import FooterNav from 'components/FooterNav';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { TypingEffect } from 'shared/Typing/TypingEffect';
import { authStore } from 'stores/AuthStore';
import {
  UserGroupIcon,
  SparklesIcon,
  HandRaisedIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  RocketLaunchIcon
} from '@heroicons/react/24/outline';
import { useEffect } from 'react';
import BlueLoader from 'shared/Loader/BlueLoader';
import { PageType, navigationStore } from 'stores/NavigationStore';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
  gradient: string;
  disabled?: boolean;
  comingSoon?: boolean;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  onClick,
  gradient,
  disabled,
  comingSoon
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`group relative p-6 bg-white rounded-2xl shadow-lg
    transition-all duration-300 overflow-hidden text-left w-full 
    ${disabled ? 'opacity-80 cursor-not-allowed' : 'hover:shadow-xl hover:-translate-y-1'}`}
  >
    <div className={`absolute inset-0 ${gradient} opacity-10 
      ${!disabled && 'group-hover:opacity-20'} transition-opacity`}
    />
    <div className="relative">
      <div className="bg-white/50 rounded-full w-12 h-12 flex items-center justify-center mb-4">
        {icon}
      </div>
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
          <p className="text-sm text-gray-600 line-clamp-2">{description}</p>
        </div>
        {comingSoon && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            Coming Soon
          </span>
        )}
      </div>
    </div>
  </button>
);

const MainPage = () => {
  const navigate = useNavigate();
  const isEnterprise = authStore.currentUser?.profile?.enterprise;
  const userCommunityId = authStore.currentUser?.communityUsers?.[0]?.community?.id;
  
  useEffect(() => {
    navigationStore.setCurrentPage(PageType.MAIN);

    if (navigationStore.shouldRedirectToCommunity() && userCommunityId) {
      navigate(`/community/${userCommunityId}`);
    }
  }, [userCommunityId]);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50">
      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-200 rounded-full opacity-20 blur-3xl"></div>
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-blue-200 rounded-full opacity-20 blur-3xl"></div>
      </div>

      {/* Main Content Container - Added mb-20 for mobile spacing */}
      <div className="relative mb-20">
        {/* Header Section */}
        <header className="pt-8 md:pt-16 px-4 text-center">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
              <TypingEffect text="Welcome to MeetWith" speed={50} />
            </h1>
            <p className="text-gray-600 text-sm md:text-lg max-w-2xl mx-auto">
              Connect with professionals, discover opportunities, and grow your network
            </p>
          </div>
        </header>

        {/* Main Features Grid - Added pb-16 for extra bottom spacing */}
        <main className="max-w-7xl mx-auto px-4 py-8 md:py-16 pb-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-12">
            <FeatureCard
              icon={<UserGroupIcon className="w-6 h-6 text-blue-600" />}
              title="Explore Network"
              description="Discover and connect with professionals in your industry"
              onClick={() => navigate('/home')}
              gradient="bg-gradient-to-br from-blue-600 to-indigo-600"
            />

            <FeatureCard
              icon={<SparklesIcon className="w-6 h-6 text-purple-600" />}
              title="AI Matching"
              description={isEnterprise
                ? "Connect with colleagues using AI-powered matching"
                : "Find perfect professional matches with AI technology"}
              onClick={() => navigate(isEnterprise ? '/ai-search-colleagues' : '/ai-search-opportunities')}
              gradient="bg-gradient-to-br from-purple-600 to-pink-600"
            />

            <FeatureCard
              icon={<HandRaisedIcon className="w-6 h-6 text-rose-600" />}
              title="Quick Connect"
              description="Swipe through potential business connections and match instantly"
              onClick={() => navigate('/meet-with')}
              gradient="bg-gradient-to-br from-rose-600 to-orange-600"
            />
          </div>

          {/* Additional Features */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <FeatureCard
              icon={<CalendarIcon className="w-6 h-6 text-green-600" />}
              title="Events & Meetups"
              description="Join professional events and networking meetups"
              onClick={() => navigate('/activities')}
              gradient="bg-gradient-to-br from-green-600 to-teal-600"
            />

            <FeatureCard
              icon={<ChatBubbleLeftRightIcon className="w-6 h-6 text-amber-600" />}
              title="Chat & Connect"
              description="Engage in meaningful conversations with your connections"
              onClick={() => navigate('/chats')}
              gradient="bg-gradient-to-br from-amber-600 to-yellow-600"
            />

            <FeatureCard
              icon={<RocketLaunchIcon className="w-6 h-6 text-cyan-600" />}
              title="Growth Opportunities"
              description="Discover business opportunities and partnerships"
              gradient="bg-gradient-to-br from-cyan-600 to-blue-600"
              disabled={true}
              comingSoon={true}
            />
          </div>
        </main>

        {/* Footer - Changed to sticky positioning */}
        <FooterNav />
      </div>
    </div>
  );
};

export default observer(MainPage);
