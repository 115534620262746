import CommunityUserBox from "components/CommunitiesSlider/CommunityUserBox";
import Heading from "components/Heading/Heading";
import { CommunityUser } from "interface/communities";
import { FC } from "react";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface CommunityMembersProps {
  className?: string;
  communityMembers?: CommunityUser[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
}

const CommunityMembersBox: FC<CommunityMembersProps> = ({
  className = "",
  communityMembers = [],
  boxCard = "box2",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  return (
    <div
      className={`nc-CommunityMembersBox listingSection__wrap relative ${className}`}
      data-nc-id="CommunityMembersBox"
    >
      <Heading desc="" isCenter>Community Members</Heading>
      <div className={`grid gap-4 md:gap-2 ${gridClassName}`}>
        {communityMembers.map((communityMember, index) =>
          boxCard === "box2" ? (
            <CommunityUserBox key={communityMember.id} communityUser={communityMember} />
          ) : (
            null
          )
        )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        <ButtonSecondary>Show me more</ButtonSecondary>
        {/* <ButtonPrimary>Become a host</ButtonPrimary> */}
      </div>
    </div>
  );
};

export default CommunityMembersBox;
