import { User } from "./user.interface";

export interface CommunityData {
  id: string;
  name: string;
  logo?: string;                   // URL for the community's logo
  description?: string;             // Brief description of the community
  country: string;                  // Country code (e.g., "IL" for Israel)
  city?: string;                    // Name of the city
  address?: string;                 // Physical address
  latitude?: number;                // Latitude for geolocation
  longitude?: number;               // Longitude for geolocation
  contactEmail?: string;            // Contact email for the community
  phone?: string;                   // Contact phone number
  website?: string;                 // URL to the community's website
  linkedin?: string;                // URL to the community's LinkedIn page
  facebook?: string;                // URL to the community's Facebook page
  instagram?: string;               // URL to the community's Instagram page
  twitter?: string;                 // URL to the community's Twitter page
  amenities?: string[];               // Description of available amenities
  gallery?: string[];               // Array of image URLs for a gallery
  communityType?: string;           // Type of workspace (e.g., "Coworking Space")
  capacity?: number;                // Maximum capacity
  pricing?: string;                 // Details about membership pricing
  about?: string;                   // Detailed "about" section
  createdAt?: Date;                 // Timestamp when the community was created
  updatedAt?: Date;                 // Timestamp when the community was last updated
  communityUsers?: CommunityUser[]; // Array of users who belong to the community
  role?: MemberRole;
}

export enum MemberRole {
  ADMIN = "admin",
  MEMBER = "member",
  MODERATOR = "moderator"
}

interface ProfileSummary {
  id: string;
  fullName: string;
  avatar: string;
  bgImg: string;
  companyName: string;
  role: string;
  industry: string;
  goals: string[];
  interests: string[];
}

// Interface for Community User with Profile Summary included
export interface CommunityUser {
  id: string;
  joinedAt: Date;
  joinedBy: string;
  profile: ProfileSummary;
  user: { id: string }
}

// Interface for Bulletin Board
export interface BulletinResponse {
  id: string; // Unique bulletin ID
  communityId: string;
  type: 'request' | 'announcement' | 'offering';
  title: string;
  content: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: string;
  user: {
    id: string;
    name: string;
    profile?: {
      bio: string;
      avatar?: string;
      fullName: string;
    };
  };
}