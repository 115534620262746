import { FC } from "react";

interface CardSkeletonProps {
  className?: string;
}

export const CardSkeleton: FC<CardSkeletonProps> = ({ className = "" }) => {
  return (
    <div className={`
      animate-pulse
      flex flex-col items-center 
      p-6 
      h-[280px] w-[240px]
      rounded-2xl
      bg-white dark:bg-neutral-900
      border border-neutral-200 dark:border-neutral-800
      ${className}
    `}>
      {/* Avatar Skeleton */}
      <div className="relative w-full flex justify-center">
        <div className="w-24 h-24 rounded-full bg-neutral-200 dark:bg-neutral-800" />
      </div>

      {/* Content Skeleton */}
      <div className="mt-4 w-full space-y-3">
        {/* Name */}
        <div className="h-6 bg-neutral-200 dark:bg-neutral-800 rounded-md w-3/4 mx-auto" />
        
        {/* Role & Company */}
        <div className="space-y-2">
          <div className="h-4 bg-neutral-200 dark:bg-neutral-800 rounded w-1/2 mx-auto" />
          <div className="h-4 bg-neutral-200 dark:bg-neutral-800 rounded w-2/3 mx-auto" />
        </div>

        {/* Industry */}
        <div className="h-4 bg-neutral-200 dark:bg-neutral-800 rounded w-1/3 mx-auto" />
      </div>

      {/* Location/Date */}
      <div className="mt-auto w-full space-y-2">
        <div className="h-4 bg-neutral-200 dark:bg-neutral-800 rounded w-1/4 mx-auto" />
        <div className="h-4 bg-neutral-200 dark:bg-neutral-800 rounded w-2/5 mx-auto" />
      </div>
    </div>
  );
}; 