import { useState, useEffect } from 'react';
import { TripDetails } from "interface/trip.interface";
import { VentureCapitalFirmFilterDto } from "api/vc-firms";
import { CommunityData } from "interface/communities";
import { ActivityResponse } from "interface/activity";
import { ProfessionalProfileDto } from "api/professionalLocals";
import { fetchLocalActivities } from "api/activities";
import { fetchLocalCommunities } from "api/communities";
import { fetchLikeMindedLocals, PROFESSIONAL_LOCALS_BASE_URL } from "api/professionalLocals";
import { MATCHING_TRAVELERS_BASE_URL } from "api/trip";
import { authStore } from "stores/AuthStore";
import { tripStore } from "stores/TripStore";

export const useHomeData = () => {
  const [activeTab, setActiveTab] = useState("Explore");
  const [currentTrip, setCurrentTrip] = useState<TripDetails | null>(null);
  const [data, setData] = useState({
    vcFirms: [] as VentureCapitalFirmFilterDto[],
    communities: [] as CommunityData[],
    activities: [] as ActivityResponse[],
    professionalLocals: [] as ProfessionalProfileDto[]
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (activeTab === "Explore") {
      handleExploreTabData();
    } else if (currentTrip) {
      handleTripTabData();
    }
  }, [activeTab, currentTrip]);

  const handleExploreTabData = async () => {
    const country = authStore.currentUser?.profile.country;
    if (!country) return;

    setIsLoading(true);
    const [localsRes, communitiesRes, activitiesRes] = await Promise.all([
      fetchLikeMindedLocals(PROFESSIONAL_LOCALS_BASE_URL),
      fetchLocalCommunities(country),
      fetchLocalActivities(country)
    ]);

    setData(prev => ({
      ...prev,
      professionalLocals: localsRes.data.items,
      communities: communitiesRes.data,
      activities: activitiesRes.data.items
    }));

    tripStore.fetchNextMonthArrivals(country);
    setIsLoading(false);
  };

  const handleTripTabData = async () => {
    if (!currentTrip) return;

    setIsLoading(true);
    const [communitiesRes, activitiesRes] = await Promise.all([
      fetchLocalCommunities(currentTrip.toCountry),
      fetchLocalActivities(currentTrip.toCountry),
      tripStore.fetchMatchingTravelers(MATCHING_TRAVELERS_BASE_URL + currentTrip.id),
      tripStore.fetchLocalsAtDestination(currentTrip.id)
    ]);

    setData(prev => ({
      ...prev,
      communities: communitiesRes.data,
      activities: activitiesRes.data.items
    }));
    setIsLoading(false);
  };

  const setActiveTabHandler = (currentTab: string, trip: TripDetails) => {
    setActiveTab(currentTab);
    setCurrentTrip(trip);
  };

  return {
    activeTab,
    currentTrip,
    data,
    isLoading,
    setActiveTabHandler
  };
}; 