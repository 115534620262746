import React, { FC } from 'react';
import MeetWithLogo from "images/brand/Brand Logo/PNG/Brand Logo Color png.png";
export interface EmptyHeaderProps {
    navType?: "MainNav1" | "MainNav2";
    className?: string;
}


const EmptyHeader: FC<EmptyHeaderProps> = ({className}) => {
    return (
    //     <div
    //     className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg ${className}`}
    //   >
        <nav className={`nc-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg ${className}`}>
            <div className="max-w-screen-xl flex flex-wrap items-center justify-center sm:justify-between mx-auto p-4">
                <a href="https://pub.meet-with.com/" className="flex items-center space-x-3 rtl:space-x-reverse">
                    <img src={MeetWithLogo} className="h-12" alt="MeetWith Logo" />
                </a>
            </div>
        </nav>
    )
};

export default EmptyHeader;