import { createActivity, CreateActivityData, fetchActivityTypes } from "api/activities";
import LocationAutocomplete from "components/Common/LocationAutocomplete";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import DatePickerCustomHeaderSingleMonth from "components/DatePickerCustomHeaderSingleMonth";
import DropZone from "components/FileUpload/DropZone";
import FooterNav from "components/FooterNav";
import moment from "moment-timezone";
import { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useSearchParams } from 'react-router-dom';
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import Textarea from "shared/Textarea/Textarea";
import FormItem from "../PageAddListing1/FormItem";

export interface PageCreateActivityProps { }

interface Community {
  id: string;
  name: string;
}

const CreateActivityPage: FC<PageCreateActivityProps> = () => {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [startDate, setStartDate] = useState<Date>(new Date);
  const [startTime, setStartTime] = useState<Date | null>(moment().startOf('day').toDate());
  const [timezone, setTimeZone] = useState<string>("");
  const [location, setLocation] = useState<google.maps.GeocoderResult>();
  const [showEndDateTime, setShowEndDateTime] = useState<boolean>(false);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);
  const [activityTypes, setActivityTypes] = useState<string[]>([]);
  const [selectedActivityType, setSelectedActivityType] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [country, setCountry] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [lat, setLat] = useState<string>("");
  const [long, setLong] = useState<string>("");

  const [loading, setLoading] = useState<boolean>(false);

  const timeZones = moment.tz.names(); // Get a list of all time zones

  const [searchParams] = useSearchParams();
  const communityId = searchParams.get('community_id');
  console.log(communityId);
  
  useEffect(() => {
    // Automatically set the user's time zone
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    setTimeZone(userTimeZone);
  }, []);

  useEffect(() => {
    const fetchActivityTypesFunc = async () => {
      try {
        const response = await fetchActivityTypes(); // Replace with your API endpoint
        setActivityTypes(response.data); // Assuming the API returns an array of strings
      } catch (error) {
        console.error("Error fetching activity types:", error);
      }
    };

    fetchActivityTypesFunc();
  }, []);


  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  };


  // Handler for location selection
  const handleLocationSelect = (locationDetails: google.maps.GeocoderResult) => {
    
    setLocation(locationDetails);
    const addressComponents = locationDetails.address_components;

    // Extract specific address components
    const getAddressComponent = (type: string) => {
      const component = addressComponents.find((comp) => comp.types.includes(type));
      return component ? component.long_name : "";
    };

    // Set relevant address components for the location fields
    setCountry(getAddressComponent("country"));
    setCity(getAddressComponent("locality") || getAddressComponent("administrative_area_level_2"));
    setState(getAddressComponent("administrative_area_level_1"));
    setLat(locationDetails.geometry.location.lat().toString());
    setLong(locationDetails.geometry.location.lng().toString());
  };


  // Handle start date change to ensure end date is not before start date
  const handleStartDateChange = (date: Date) => {
    setStartDate(date);
    if (endDate && date && endDate < date) {
      setEndDate(null); // Reset end date if it's before the new start date
    }
  };

  // Handle start date change to ensure end date is not before start date
  const handleEndDateChange = (date: Date) => {
    setEndDate(date);
  };

  const handleActivityTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedActivityType(event.target.value);
  };

  const handleFileSelect = (selectedFile: File | null) => {
    setFile(selectedFile);
  };

  // Form submission handler
  const handleSubmit = async () => {
    // Validate required fields
    if (!title.trim() || !description.trim() || !location?.formatted_address?.trim() || !startDate || !startTime) {
      toast.error("Please fill in all required fields: title, description, time, and location.");
      return;
    }

    // Prepare data to send to backend
    const activityData: CreateActivityData = {
      title,
      description,
      startDate: moment(startDate).toISOString(),
      startTime: moment(startTime).format("HH:mm:ss"),
      endDate: endDate ? moment(endDate).toISOString() : null,
      endTime: endTime ? moment(endTime).format("HH:mm:ss") : null, // Convert endTime to time-only format if it exists
      timezone,
      country,
      city,
      state,
      lat,
      long,
      placeDescription: location?.formatted_address || "",
      placeId: location?.place_id || "",
      activityType: selectedActivityType,
      file, // Assuming your backend supports receiving the file directly like this
      communityId: communityId
    };

    try {
      setLoading(true);
      // Send data to backend (replace with your API endpoint)
      const response = await createActivity(activityData);
      if (response.status === 201) {
        toast.info("Activity created successfully!", { theme: "light" });
        resetFormFields(); // Reset form fields after successful submission
      }
    } catch (error) {
      console.error("Error creating activity:", error);
      toast.error("Failed to create activity. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Function to reset all form fields after successful submission
  const resetFormFields = () => {
    setTitle("");
    setDescription("");
    setStartDate(new Date());
    setStartTime(moment().startOf('day').toDate());
    setEndDate(null);
    setEndTime(null);
    setTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    setLocation(undefined);
    setSelectedActivityType("");
    setFile(null);
  };

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-8 sm:py-10 lg:pb-32`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11">
        <h2 className="text-2xl font-semibold">Create event</h2>
        {/* FORM */}
        <div className="space-y-4">
          <FormItem>
            <Input
              placeholder="Activity name"
              fontClass="text-md"
              onChange={handleTitleChange}
              value={title}
            />
          </FormItem>
          <Textarea
            placeholder="Activity description"
            rows={4}
            value={description}
            onChange={handleDescriptionChange}
          />

          {/* DATE, TIME, TIMEZONE FIELDS */}
          <div className="flex flex-col sm:flex-row gap-4">
            <DatePicker
              popperClassName="bg-white mt-4 z-50"
              className="flex-1 w-full border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 bg-white"
              placeholderText="Select the date of the activity"
              selected={startDate}
              onChange={handleStartDateChange}
              dateFormat="MMMM d, yyyy"
              minDate={new Date}
              monthsShown={1}
              renderCustomHeader={(p) => (
                <DatePickerCustomHeaderSingleMonth {...p} customHeaderCount={2} />
              )}
              renderDayContents={(day, date) => (
                <DatePickerCustomDay
                  dayOfMonth={day}
                  date={date}
                />
              )}
            />
            <DatePicker
              popperClassName="w-full m-4 datepicker-popper bg-white mt-4 max-h-56 overflow-y-auto z-50"
              placeholderText="Select the time of the activity"
              selected={startTime}
              onChange={(time) => setStartTime(time)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
              className="w-full border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 bg-white"
            />
            <Select
              value={timezone}
              onChange={(e) => setTimeZone(e.target.value)}
              sizeClass="h-10"
              className="flex-1 w-full border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 font-medium"
            >
              <option value="">Select Time Zone</option>
              {timeZones.map((zone) => (
                <option key={zone} value={zone}>
                  {zone}
                </option>
              ))}
            </Select>
          </div>
          {/* BUTTON TO ADD END DATE AND TIME */}
          <div className="mt-4">
            <button
              type="button"
              onClick={() => setShowEndDateTime(!showEndDateTime)}
              className="text-blue-600 hover:underline"
            >
              + End date and time
            </button>
          </div>

          {/* END DATE AND TIME FIELDS */}
          {showEndDateTime && (
            <div className="flex flex-col sm:flex-row gap-4 mt-4">
              <DatePicker
                popperClassName="bg-white mt-4 z-50"
                className="w-full border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 bg-white"
                placeholderText="Select the end date of the activity"
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat="MMMM d, yyyy"
                minDate={startDate || new Date()} // Ensure end date is after or equal to start date
                monthsShown={1}
                renderCustomHeader={(p) => (
                  <DatePickerCustomHeaderSingleMonth {...p} customHeaderCount={2} />
                )}
                renderDayContents={(day, date) => (
                  <DatePickerCustomDay dayOfMonth={day} date={date} />
                )}
              />
              <DatePicker
                popperClassName="w-full m-4 datepicker-popper bg-white mt-4 max-h-56 overflow-y-auto z-50"
                placeholderText="Select the end time of the activity"
                selected={endTime}
                onChange={(time) => setEndTime(time)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeCaption="Time"
                dateFormat="h:mm aa"
                className="w-full border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 bg-white"
                minTime={
                  startDate && endDate && startDate.getTime() === endDate.getTime() && startTime
                    ? startTime
                    : undefined
                } // If start date and end date are the same, ensure end time is after start time
              />
            </div>
          )}
          {/* LOCATION AUTOCOMPLETE */}
          <FormItem>
            <LocationAutocomplete
              types="establishment"
              address={location?.formatted_address || ""}
              placeHolder="Enter activity location..."
              onLocationSelect={handleLocationSelect}
            />
          </FormItem>
          {/* Activity Type */}
          <FormItem>
            <Select
              value={selectedActivityType}
              onChange={handleActivityTypeChange}
              className="w-full border border-gray-300 rounded-2xl px-4 py-2 focus:outline-none focus:ring-2 focus:ring-primary-500 font-medium"
            >
              <option value="">Choose activity type</option>
              {activityTypes.length > 0 ? (
                activityTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))
              ) : (
                <option value="">Loading...</option>
              )}
            </Select>

          </FormItem>
          <DropZone onFileSelect={handleFileSelect} />
        </div>
        <div className="flex justify-end space-x-5">
          {/* <ButtonSecondary href={'backtHref'}>Go back</ButtonSecondary> */}
          <ButtonPrimary className="w-full sm:w-auto" onClick={handleSubmit}>Create</ButtonPrimary>
        </div>
      </div>
      <FooterNav />
    </div>
  );
};

export default CreateActivityPage;
