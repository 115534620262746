import { BuildingLibraryIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import BadgeScore from "shared/BadgeScore/BadgeScore";

export interface CardLikeMindedLocalsBoxProps {
  className?: string;
  likeMindedLocal: any;
  index?: number;
}

const CardLikeMindedLocalsBox: FC<CardLikeMindedLocalsBoxProps> = ({
  className = "",
  likeMindedLocal,
  index,
}) => {
  const { id, displayName, avatar, city, role, industry, matchScore, companyName } = likeMindedLocal;

  return (
    <Link
      to={`/user/${id}`}
      className={`
        nc-CardTripBox relative 
        flex flex-col items-center 
        p-6 
        h-[280px] w-[240px]
        rounded-2xl
        transition-all duration-300
        bg-white dark:bg-neutral-900
        hover:shadow-xl hover:scale-105
        border border-neutral-200 dark:border-neutral-800
        ${className}
      `}
    >
      {/* Top Section with Badge and Avatar */}
      <div className="relative w-full flex justify-center">
        {index && (
          <BadgeScore
            className="absolute -top-3 -right-3 z-10"
            name=""
            matchScore={matchScore}
          />
        )}
        <Avatar
          sizeClass="w-24 h-24 text-2xl"
          radius="rounded-full"
          imgUrl={avatar}
          userName={displayName}
          containerClassName="ring-4 ring-white dark:ring-neutral-900 shadow-lg"
        />
      </div>

      {/* User Info Section */}
      <div className="mt-4 text-center w-full">
        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
          {displayName}
        </h2>
        <div className="mt-1 text-sm text-neutral-500 dark:text-neutral-400 space-y-1">
          <p className="truncate">{role}</p>
          {companyName && (
            <p className="truncate text-xs">
              @ {companyName}
            </p>
          )}
          <p className="font-medium text-neutral-700 dark:text-neutral-300">{industry}</p>
        </div>
      </div>

      {/* Location Section */}
      <div className="mt-auto w-full">
        <div className="text-xs text-neutral-500 dark:text-neutral-400">
          <div className="flex items-center justify-center gap-1">
            <span>{city}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardLikeMindedLocalsBox;
