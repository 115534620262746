import { CalendarIcon } from '@heroicons/react/24/outline';
import { fetchLocalActivities } from 'api/activities';
import CardActivityCreator from 'components/ActivityCreator/CardActivityCreator';
import FooterNav from 'components/FooterNav';
import { ActivityResponse } from 'interface/activity';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlueLoader from 'shared/Loader/BlueLoader';
import { authStore } from 'stores/AuthStore';

const ActivitiesPage = () => {
    const [activities, setActivities] = useState<ActivityResponse[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const userCountry = authStore.currentUser?.profile?.country || '';
    const navigate = useNavigate();

    useEffect(() => {
        fetchActivities();
    }, [userCountry]);

    const fetchActivities = async () => {
        if (!userCountry) {
            setIsLoading(false);
            return;
        }

        try {
            setIsLoading(true);
            const response = await fetchLocalActivities(userCountry);
            setActivities(response.data.items);
        } catch (error) {
            console.error('Failed to fetch activities:', error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <BlueLoader />;
    }

    return (
        <div className="min-h-screen bg-gray-50 pb-20">
            {/* Header with gradient background */}
            <div className="bg-gradient-to-r from-white to-gray-50 border-b">
                <div className="max-w-7xl mx-auto px-4 py-8 md:py-12">
                    <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
                        Activities & Meetups
                    </h1>
                    <p className="text-sm md:text-base text-gray-600 mt-2">
                        Showing activities in {userCountry || 'your area'}
                    </p>
                </div>
            </div>

            {/* Activities Grid - Updated grid classes for mobile */}
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    {activities.map((activity) => (
                        <div key={activity.id} className="flex flex-col h-full transform hover:-translate-y-1 transition-all duration-200">
                            <CardActivityCreator activity={activity} />
                        </div>
                    ))}
                </div>

                {activities.length === 0 && (
                    <div className="bg-white rounded-xl shadow-sm p-8 text-center max-w-lg mx-auto">
                        <CalendarIcon className="w-16 h-16 mx-auto text-gray-400 mb-4" />
                        <h3 className="text-xl font-semibold text-gray-900 mb-2">
                            No activities found
                        </h3>
                        <p className="text-gray-500">
                            There are no upcoming activities in your area at the moment.
                        </p>
                    </div>
                )}
            </div>

            <FooterNav />
        </div>
    );
};

export default observer(ActivitiesPage); 