import { observer } from "mobx-react";
import { FC } from "react";
import { toast } from "react-toastify";
import { onboardingStore } from "stores/OnboardingStore";
import CommonLayout from "./CommonLayout";
import InterestsWidgetTags from "./InterestsSelection/InterestsWidgetTags";

export interface OnBoardingStep3Props { }

const OnBoardingStep3: FC<OnBoardingStep3Props> = observer(() => {

  const handleContinue = () => {    
    if (onboardingStore.goals.length) {
      onboardingStore.setOnboardingNextStep()
    } else {
      toast.error('Please select at least 1 goal', {theme: 'light'})
    }
  }

  const handlePrevStep = () => onboardingStore.setOnboardingPrevStep()

  return (
    <CommonLayout
      index="3"
      onContinue={handleContinue}
      onBack={handlePrevStep}
    >
      <>
      <h2 className="text-2xl font-semibold">🎯 Tell us your goals</h2>
      <p className="text-gray-500 mb-6">Set your goals to let AI find tailored connections and opportunities that help you succeed!</p>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <InterestsWidgetTags />
        </div>
      </>
    </CommonLayout>
  );
})

export default OnBoardingStep3;
