import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SectionGridActivityBox from '../../components/SectionGridActivityBox/SectionGridActivityBox';
import { ActivityResponse } from 'interface/activity';
import { getActivitiesByCommunityId } from '../../api/activities';
import Heading from 'components/Heading/Heading';

interface CommunityActivitiesContainerProps {
    communityId: string;
}

const CommunityActivitiesContainer = () => {
    const [searchParams] = useSearchParams();
    const communityId = searchParams.get('community_id');
    const [activities, setActivities] = useState<ActivityResponse[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchActivities = async () => {
            if (!communityId) return;
            
            try {
                const response = await getActivitiesByCommunityId(communityId);
                setActivities(response.data.items);
            } catch (error) {
                console.error('Error fetching community activities:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchActivities();
    }, [communityId]);

    if (loading) {
        return <div className="p-4">Loading activities...</div>;
    }

    return (
        <div className="container mx-auto mt-4">
            <Heading desc="Discover the activities of this community" >Community Activities</Heading>
            <SectionGridActivityBox boxCard="box2" activities={activities} />
        </div>
    );
}

export default CommunityActivitiesContainer;