import { UserProfileDTO } from 'api/dto/get-user-data-by-id.dto';
import { Timestamp, addDoc, collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, setDoc, startAfter, updateDoc } from 'firebase/firestore';
import { User } from 'interface/user.interface';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import { chatStore } from 'stores/ChatStore';
import { generateConversationId } from 'utils/strings';
import { firestore } from '../../firebase';
import Message from './Message';

interface ChatProps {
    user: User;
    recipient: UserProfileDTO;
}

interface Message {
    id: string;
    senderId: string;
    receiverId: string;
    message: string;
    timestamp: Timestamp;
    status: 'sent' | 'received' | 'read';
}

interface UnreadCount {
    [conversationId: string]: number;
}

const Chat: React.FC<ChatProps> = ({ user, recipient }) => {
    const [message, setMessage] = useState<string>('');
    const [messages, setMessages] = useState<Message[]>([]);
    const [lastMessage, setLastMessage] = useState<any | null>(null);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const pageSize = 10;  // Number of messages to load per page
    const conversationId = generateConversationId(user.id, recipient.id);
    const [unreadCount, setUnreadCount] = useState<number>(0);
    const navigate = useNavigate();
    useEffect(() => {
        const messagesRef = collection(firestore, 'conversations', conversationId, 'messages');
        const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(pageSize));

        const unsubscribe = onSnapshot(q, (snapshot) => {

            if (snapshot.docs.length < pageSize) {
                setHasMore(false);
            }

            const messagesData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            } as Message));

            setMessages(messagesData.reverse());
            if (snapshot.docs.length > 0) {
                setLastMessage(snapshot.docs[snapshot.docs.length - 1].data().timestamp);
            }
        });

        return () => unsubscribe();
    }, [conversationId]);

    useEffect(() => {
        // Set active conversation when chat is opened
        chatStore.setActiveConversation(conversationId);

        return () => {
            // Clear active conversation when component unmounts
            chatStore.clearActiveConversation();
        };
    }, [conversationId]);

    useEffect(() => {
        // Listen for unread messages
        const conversationRef = doc(firestore, 'conversations', conversationId);

        const unsubscribe = onSnapshot(conversationRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                if (data.status[user.id] === 'not-seen') {
                    chatStore.setUnreadMessages(true);
                }
            }
        });

        return () => unsubscribe();
    }, [conversationId, user.id]);

    const loadMessages = async () => {
        console.log('Loading more messages...');
        const messagesRef = collection(firestore, 'conversations', conversationId, 'messages');
        let q = query(messagesRef, orderBy('timestamp', 'desc'), limit(pageSize));

        if (lastMessage) {
            q = query(messagesRef, orderBy('timestamp', 'desc'), startAfter(lastMessage), limit(pageSize));
        }

        const snapshot = await getDocs(q);
        if (snapshot.docs.length < pageSize) {
            setHasMore(false);
        }

        const messagesData = snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        } as Message));

        setMessages(prevMessages => [...messagesData.reverse(), ...prevMessages]);
        if (snapshot.docs.length > 0) {
            setLastMessage(snapshot.docs[snapshot.docs.length - 1].data().timestamp);
        }
    };

    const createConversation = async () => {
        const conversationRef = doc(firestore, 'conversations', conversationId);
        const conversationDoc = await getDoc(conversationRef);

        if (!conversationDoc.exists()) {
            await setDoc(conversationRef, {
                participants: [user.id, recipient.id],
                participantsMetaData: {
                    [user.id]: {
                        avatar: user?.profile?.avatar,
                        fullName: user?.profile?.fullName
                    },
                    [recipient.id]: {
                        avatar: recipient?.profile?.avatar,
                        fullName: recipient?.profile?.fullName
                    }
                },
                lastMessage: '',
                status: {
                    [user.id]: 'seen',
                    [recipient.id]: 'not-seen'
                }
            });
        }
    };

    const sendMessage = async () => {
        if (message.trim()) {
            const newMessage = {
                senderId: user.id,
                receiverId: recipient.id,
                message,
                timestamp: serverTimestamp() as Timestamp,
                status: 'sent'
            };

            const messagesRef = collection(firestore, 'conversations', conversationId, 'messages');
            await addDoc(messagesRef, newMessage);

            await updateDoc(doc(firestore, 'conversations', conversationId), {
                lastMessage: message,
                sentAt: moment.utc().toISOString(),
                [`status.${recipient.id}`]: 'not-seen',
                [`status.${user.id}`]: 'seen',
                lastSenderId: user.id
            });

            setMessage('');
        }
    };

    const markAsRead = async (messageId: string) => {
        const messageRef = doc(firestore, 'conversations', conversationId, 'messages', messageId);
        await updateDoc(messageRef, { status: 'read' });
    };

    const markConversationAsRead = async () => {
        const conversationRef = doc(firestore, 'conversations', conversationId);
        await updateDoc(conversationRef, {
            [`status.${user.id}`]: 'seen'
        });
        chatStore.setUnreadMessages(false);
    };

    useEffect(() => {
        createConversation();

        messages.forEach(msg => {
            if (msg.receiverId === user.id && msg.status === 'sent') {
                markAsRead(msg.id);
            }
        });

        // Mark as read when chat is opened
        markConversationAsRead();
    }, [messages, user.id]);


    return (
        <>
            <div className="flex items-center px-4 py-3 bg-white border-b shadow-sm">
                <div className="flex items-center space-x-3">
                    <Avatar
                        imgUrl={recipient.profile.avatar}
                        sizeClass="w-8 h-8 md:w-10 md:h-10"
                    />
                    <div
                        className="cursor-pointer hover:opacity-80"
                        onClick={() => navigate(`/user/${recipient.id}`)}
                    >
                        <h3 className="font-medium text-sm md:text-base">
                            {recipient.profile.fullName}
                        </h3>
                        <p className="text-xs text-gray-500">
                            {recipient.profile.role}
                        </p>
                    </div>
                </div>
            </div>
            <div id="scrollableDiv" className="chat overflow-y-auto" style={{
                display: 'flex', flexDirection: 'column-reverse', height: 'calc(100vh - 156px)',
                maxHeight: 'calc(100vh - 156px)',
            }}>
                <InfiniteScroll
                    dataLength={messages.length}
                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                    next={loadMessages}
                    hasMore={hasMore}
                    loader={<h4>Loading...</h4>}
                    // inverse={true} // Inverse to have the scroll start at the bottom
                    scrollableTarget="scrollableDiv"
                >
                    <div className="messages">
                        {messages.map(msg => (
                            <Message key={msg.id} text={msg.message} date={msg.status} me={msg.senderId === user.id} recipientAvatar={recipient.profile.avatar} />
                        ))}
                    </div>
                </InfiniteScroll>
            </div>
            <div className="input" style={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: 'flex', alignItems: 'center', padding: '8px', backgroundColor: '#f0f0f0' }}>
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            sendMessage();
                            e.preventDefault(); // Prevents the addition of a new line in the input after pressing 'Enter'
                        }
                    }}
                    placeholder="Type a message..."
                    style={{ flex: 1, border: 'none', outline: 'none', borderRadius: '20px', padding: '8px 16px', marginRight: '8px' }}
                />
                <button style={{ backgroundColor: '#128C7E', border: 'none', outline: 'none', borderRadius: '50%', width: '40px', height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={sendMessage}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M22 2L11 13"></path>
                        <path d="M22 2L15 22L11 13L2 9L22 2"></path>
                    </svg>
                </button>
            </div>
        </>
    );
};

export default Chat;