import { AxiosError, AxiosResponse } from 'axios';
import { BulletinResponse } from 'interface/communities';
import { PaginationResult } from 'interface/pagination';
import axiosInstance from 'utils/setAuthorizationHeader';

export const BULLETINS_BASE_URL = `${process.env.REACT_APP_API_URL}/bulletins`;

export interface CreateBulletinData {
  communityId: string; // ID of the community
  type?: 'request' | 'announcement' | 'offering'; // Type of bulletin
  title: string; // Title of the bulletin
  content: string; // Content or description of the bulletin
}

// Fetch bulletins for a specific community
export async function fetchBulletinsByCommunity(
  communityId: string
): Promise<AxiosResponse<BulletinResponse[]>> {
  return axiosInstance.get(`${BULLETINS_BASE_URL}/community/${communityId}`, {
    withCredentials: true,
  });
}

// Fetch a specific bulletin by ID
export async function fetchBulletinById(
  bulletinId: string
): Promise<AxiosResponse<BulletinResponse>> {
  return axiosInstance.get(`${BULLETINS_BASE_URL}/${bulletinId}`, {
    withCredentials: true,
  });
}

// Create a new bulletin
export async function createBulletin(
  bulletinData: CreateBulletinData
): Promise<AxiosResponse<BulletinResponse>> {
  return axiosInstance.post(`${BULLETINS_BASE_URL}`, bulletinData, {
    withCredentials: true,
  });
}

// Update an existing bulletin
export async function updateBulletin(
  bulletinId: string,
  bulletinData: Partial<CreateBulletinData>
): Promise<AxiosResponse<BulletinResponse>> {
  return axiosInstance.put(`${BULLETINS_BASE_URL}/${bulletinId}`, bulletinData, {
    withCredentials: true,
  });
}

// Delete a bulletin
export async function deleteBulletin(
  bulletinId: string
): Promise<AxiosResponse<void>> {
  return axiosInstance.delete(`${BULLETINS_BASE_URL}/${bulletinId}`, {
    withCredentials: true,
  });
}

// Approve a bulletin
export async function approveBulletin(
  bulletinId: string
): Promise<AxiosResponse<BulletinResponse>> {
  return axiosInstance.post(`${BULLETINS_BASE_URL}/${bulletinId}/approve`, null, {
    withCredentials: true,
  });
}

// Reject a bulletin
export async function rejectBulletin(
  bulletinId: string
): Promise<AxiosResponse<BulletinResponse>> {
  return axiosInstance.post(`${BULLETINS_BASE_URL}/${bulletinId}/reject`, null, {
    withCredentials: true,
  });
}
