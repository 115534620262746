import { getMatches, submitUserMatchInteraction } from "api/matches";
import FooterNav from "components/FooterNav";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Lottie from 'react-lottie';
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import swipeLottie from "../../images/lottie/swipe.json";
import 'swiper/css';

import Avatar from "shared/Avatar/Avatar";
import { useNavigate } from "react-router-dom";

const MeetWithPage: React.FC = () => {
    const cardRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();

    const [matches, setMatches] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isSwiping, setIsSwiping] = useState(false);
    const [startX, setStartX] = useState(0);
    const [currentTranslate, setCurrentTranslate] = useState(0);
    const [showLottie, setShowLottie] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLottie(false);
        }, 4000);

        return () => clearTimeout(timer); // Clean up the timer if the component unmounts
    }, []);

    useEffect(() => {
        const fetchMatches = async () => {
            setLoading(true);
            try {
                const matches = await getMatches();
                setMatches(matches.data.items);
            } catch (error) {
                console.error('Failed to fetch matches:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchMatches();
    }, []);

    const handleUserMatchAction = async (userIndex: number, action: 'L' | 'D'): Promise<boolean> => {
        const targetUser = matches[userIndex];

        if (!targetUser) {
            console.error('No user found at the provided index');
            return false
        }

        try {
            const { data } = await submitUserMatchInteraction(targetUser.id, action)
            if (data) {
                navigate(`/user/${targetUser.id}`);
            }
        } catch (error) {
            console.error('An error occurred while sending the user action to the backend:', error);
            toast.error('An error occurred. Please try again later.');
        } finally {
            return false;
        }
    };

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setStartX(e.touches[0].clientX);
        setIsSwiping(true);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!isSwiping) return;

        const currentX = e.touches[0].clientX;
        const diffX = currentX - startX;

        setCurrentTranslate(diffX);
    };

    const handleTouchEnd = () => {
        setIsSwiping(false);

        if (currentTranslate > 100) {
            handleSwipe('left');
        } else if (currentTranslate < -100) {
            handleSwipe('right');
        } else {
            setCurrentTranslate(0);
        }
    };

    const handleSwipe = async (direction: string) => {
        const person = matches[currentIndex];

        if (direction === 'right') {
            await handleUserMatchAction(currentIndex, 'L');
        } else if (direction === 'left') {
            await handleUserMatchAction(currentIndex, 'D');
        }
        setMatches(prevMatches => prevMatches.filter((people) => people.id !== person.id));
        setCurrentIndex((prevIndex) => 0);
        setCurrentTranslate(0);
    };

    const handleSwipeAnimation = (direction: 'left' | 'right') => {
        const card = cardRef.current;
        if (!card) return;

        // Add transform and opacity animation
        card.style.transition = 'transform 0.5s, opacity 0.5s';
        card.style.transform = `translate(${direction === 'right' ? '150%' : '-150%'}) rotate(${direction === 'right' ? '30deg' : '-30deg'})`;
        card.style.opacity = '0';

        // After animation, handle the swipe logic
        setTimeout(async () => {
            await handleSwipe(direction);
            // Reset card position for next card
            card.style.transition = 'none';
            card.style.transform = 'translate(0) rotate(0)';
            card.style.opacity = '1';
        }, 500);
    };

    if (currentIndex >= matches.length) {
        return <div className="text-center mt-20">No more matches</div>;
    }

    const lottieOptions = {
        loop: true,
        autoplay: true,
        animationData: swipeLottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    return (
        <div className="nc-MeetWithPage">
            <Helmet>
                <title>Meet With - Swipe For Connections</title>
            </Helmet>
            <div className="container relative min-h-screen pb-20">
                {loading ? (
                    <div className="flex justify-center items-center h-[60vh]">
                        <BeatLoader color="#123abc" />
                    </div>
                ) : matches.length === 0 ? (
                    <div className="flex flex-col items-center justify-center h-[60vh] text-xl text-gray-600">
                        No more matches available
                    </div>
                ) : (
                    <div className="relative max-w-md mx-auto pt-8">
                        {/* Desktop Buttons */}
                        <div className="flex justify-center gap-6 mb-4">
                            <button 
                                onClick={() => handleSwipeAnimation('left')}
                                className="p-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-110"
                                aria-label="Pass"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                            <button 
                                onClick={() => handleSwipeAnimation('right')}
                                className="p-4 rounded-full shadow-lg hover:shadow-xl transition-all duration-200 hover:scale-110"
                                aria-label="Like"
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                </svg>
                            </button>
                        </div>

                        {/* Card Stack */}
                        <div 
                            className="relative h-[calc(100vh-250px)] touch-pan-y"
                            onTouchStart={handleTouchStart}
                            onTouchMove={handleTouchMove}
                            onTouchEnd={handleTouchEnd}
                        >
                            {matches.map((person, index) => (
                                <div
                                    key={person.id}
                                    ref={index === currentIndex ? cardRef : null}
                                    className={`
                                        absolute w-full transition-all duration-300 transform
                                        ${index === currentIndex ? 'z-10 scale-105' : 'z-0 scale-100'}
                                        ${index === currentIndex + 1 ? 'z-0 scale-95 opacity-50' : 'opacity-0'}
                                    `}
                                    style={{
                                        transform: index === currentIndex ? 
                                            `translateX(${currentTranslate}px) rotate(${currentTranslate * 0.1}deg)` : 
                                            undefined
                                    }}
                                >
                                    <div className=" rounded-xl shadow-xl overflow-hidden p-4">
                                        <div className="flex flex-col items-center">
                                            <Avatar
                                                imgUrl={person.profile.avatar}
                                                userName={person.profile.fullName}
                                                sizeClass="w-32 h-32 rounded-full object-cover mb-4"
                                            />
                                            <h3 className="text-xl font-bold">
                                                {person.profile.fullName}
                                            </h3>
                                            <p className="text-black-500 font-bold mb-2">
                                                {person.profile.role} at {person.profile.companyName}
                                            </p>
                                            <p className="text-black-500 font-bold mb-2">
                                                {person.profile.industry}
                                            </p>
                                            <p className="text-black-400 font-bold">
                                                {person.profile.city}, {person.profile.country}
                                            </p>
                                            <p className="text-sm mt-4 text-center">
                                                {person.profile.bio}
                                            </p>
                                        </div>

                                        <div className="mt-6">
                                            <h4 className="text-lg font-bold">Interests</h4>
                                            <ul className="list-none text-sm text-gray-600 mt-2 flex flex-wrap gap-2">
                                                {person?.profile?.interests?.map((interest: string, i: number) => (
                                                    <li
                                                        key={i}
                                                        className="inline-block px-3 py-1 bg-gray-200 text-gray-800 rounded-full"
                                                    >
                                                        {interest}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>

                                        <div className="mt-6">
                                            <h4 className="text-lg font-bold">Languages</h4>
                                            <ul className="list-none text-sm text-gray-600 mt-2 flex flex-wrap gap-2">
                                                {person?.profile?.languages?.map((language: string, i: number) => (
                                                    <li
                                                        key={i}
                                                        className="inline-block px-3 py-1 bg-gray-200 text-gray-800 rounded-full"
                                                    >
                                                        {language}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        {/* Swipe Tutorial Overlay */}
                        {showLottie && (
                            <div className="fixed inset-0 z-40 flex items-center justify-center bg-black bg-opacity-50 backdrop-blur-sm">
                                <div className="p-6 rounded-lg text-white text-center">
                                    <Lottie options={lottieOptions} height={100} width={200} />
                                    <p className="mt-4 text-lg">
                                        {window.innerWidth >= 768 ? 
                                            "Click buttons to accept or pass" : 
                                            "Swipe right to accept, left to pass"}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <FooterNav />
        </div>
    );
};

export default MeetWithPage;
