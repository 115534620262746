import { sendEnterpriseMatchSearchRequest, sendEnterpriseQuickMatchSearchRequest } from 'api/matches';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Avatar from 'shared/Avatar/Avatar';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useFilter } from './FilterContext';

interface Match {
    id: string;
    fullName: string;
    industry: string;
    role: string;
    country: string;
    companyName: string;
    percentage: number;
    avatarUrl: string;
    userId: string;
    desc: string;
}

const Chat = ({ onMatchClick, ...props }: { onMatchClick: (match: Match) => void }) => {
    const [message, setMessage] = useState<string>('');
    const [matches, setMatches] = useState<Match[]>([]);
    const [isSending, setIsSending] = useState<boolean>(false);

    const {
        isQuickMatch
    } = useFilter();

    useEffect(() => {
        const sendEnterpriseQuickMatchSearchRequestFunc = async () => {
            // Add your API request logic here
            console.log("Sending quick match search request");
            setIsSending(true);  // Disable the send button

            try {
                const response = await sendEnterpriseQuickMatchSearchRequest();
                if (response.length === 0) {
                    toast.info('No matches found.');
                }
                setMatches(response);
            } catch (error) {
                console.error('Error searching for matches:', error);
            } finally {
                setIsSending(false);  // Enable the send button again
                setMessage('');  // Clear the input field
            }
        };

        if (isQuickMatch) {
            sendEnterpriseQuickMatchSearchRequestFunc();
        }

    }, [isQuickMatch]);

    const sendMessage = async () => {
        if (!message.trim()) return;

        setIsSending(true);  // Disable the send button

        try {
            const response = await sendEnterpriseMatchSearchRequest(message);
            if (response.length === 0) {
                toast.info('No matches found.');
            }
            setMatches(response);
        } catch (error) {
            console.error('Error searching for matches:', error);
        } finally {
            setIsSending(false);  // Enable the send button again
            setMessage('');  // Clear the input field
        }
    };

    return (
        <div className="container">
            <div
                id="scrollableDiv"
                className="flex flex-col mt-4 space-y-4"
                style={{ height: 'calc(100vh - 200px)' }}
            >
                {matches.map((match, index) => (
                    <div
                        key={index}
                        className="p-4 border border-gray-300 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition duration-300 bg-white flex flex-col space-y-4"
                        onClick={() => onMatchClick(match)}
                    // onClick={() => handleMatchClick(match.userId)}
                    >
                        {/* Top Section with Avatar and Main Info */}
                        <div className="flex items-start justify-between space-x-4">
                            {/* Avatar Section */}
                            <div className="flex-shrink-0">
                                <Avatar imgUrl={match.avatarUrl} userName={match?.fullName} sizeClass="w-12 h-12 rounded-full" />
                            </div>

                            {/* Info Section */}
                            <div className="flex-1 min-w-0">
                                <h2 className="text-sm md:text-xl font-semibold">{match.fullName}</h2>
                                <p className="text-xs md:text-base text-gray-500 truncate">{match.industry}</p>
                                <p className="text-xs md:text-base text-gray-600 truncate">{match.role}</p>
                            </div>

                            {/* Company & Match Info */}
                            <div className="flex-shrink-0 text-right">
                                <p className="text-xs md:text-base text-gray-600 overflow-hidden truncate whitespace-nowrap max-w-[120px]" title={match.companyName}>
                                    {match.companyName}
                                </p>
                                <p className="text-xs md:text-base text-gray-500 overflow-hidden truncate whitespace-nowrap max-w-[120px]" title={match.country}>
                                    {match.country}
                                </p>
                                <p className="text-xs md:text-base font-semibold text-green-500">{match.percentage}% Match</p>
                            </div>
                        </div>

                        {/* Description at the Bottom */}
                        <div className="mt-auto text-xs text-gray-500 line-clamp-3">
                            {match.desc}
                        </div>
                    </div>
                ))}
            </div>


            <div
                className="fixed bottom-0 left-0 right-0 flex items-center p-4 bg-gray-100"
            >
                <input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            sendMessage();
                            e.preventDefault(); // Prevent new line in input
                        }
                    }}
                    placeholder="Start typing to find matches..."
                    className="flex-1 bg-white border border-gray-300 rounded-full px-4 py-2 mr-2 focus:outline-none focus:ring-2 focus:ring-blue-500 text-base md:text-lg transition-all duration-300"
                    disabled={isSending}  // Disable input while sending
                    style={{ height: '3rem' }}
                />
                <ButtonPrimary
                    onClick={sendMessage}
                    disabled={isSending}  // Disable button while sending
                    loading={isSending}  // Show loading spinner if sending
                >Send</ButtonPrimary>
            </div>
        </div>
    );
};

export default Chat;