import { fetchBulletinsByCommunity } from "api/bulletins";
import { FC, useEffect, useState } from "react";
import DigitalBulletinBoardCard from "./DigitalBulletinBoardCard";
import DigitalBulletinHeading from "./DigitalBulletinHeading";
import { BulletinResponse } from "interface/communities";
import SkeletonBulletinCard from "shared/Skeletons/SkeletonBulletinCard";

export interface DigitalBulletinBoardProps {
  className?: string;
  communityId: string; // Pass the community ID as a prop
}

const DigitalBulletinBoard: FC<DigitalBulletinBoardProps> = ({
  className = "shadow-xl dark:bg-neutral-800",
  communityId,
}) => {
  const [bulletins, setBulletins] = useState<BulletinResponse[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const handleNewBulletin = (newBulletin: BulletinResponse) => {
    setBulletins(prevBulletins => [newBulletin, ...prevBulletins]);
  };

  useEffect(() => {
    // Fetch bulletins for the given community
    const fetchBulletins = async () => {
      if (!communityId) return
      try {
        setLoading(true);
        const response = await fetchBulletinsByCommunity(communityId);
        setBulletins(response.data); // Assuming the response structure includes `items` for paginated results
        setLoading(false);
      } catch (err: any) {
        console.error("Error fetching bulletins:", err.message || err);
      }finally {
        setLoading(false);
      }
    };

    fetchBulletins();
  }, [communityId]);


  return (
    <div
      className={`nc-DigitalBulletinBoard rounded-2xl border-b border-t border-l border-r border-neutral-200 overflow-hidden ${className}`}
      data-nc-id="DigitalBulletinBoard"
    >
      <DigitalBulletinHeading
        title="💬 Community Board"
        communityId={communityId}
        onBulletinCreated={handleNewBulletin}
      />
      <div className="flow-root">
        <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
        {loading ? (
            // Render skeleton cards while loading
            Array.from({ length: 2 }).map((_, index) => <SkeletonBulletinCard key={index} />)
          ) : bulletins.length > 0 ? (
            // Render bulletins after loading
            bulletins.map((bulletin) => (
              <DigitalBulletinBoardCard
                className="p-4 xl:px-5 xl:py-6 hover:bg-neutral-100 dark:hover:bg-neutral-700"
                key={bulletin.id}
                bulletin={bulletin}
              />
            ))
          ) : (
            // Render call-to-action message when no bulletins are available
            <div className="p-6 text-center">
              <p className="text-lg font-semibold text-neutral-700 dark:text-neutral-300">
                No announcements yet.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DigitalBulletinBoard;
