import { useEffect } from "react";
import MyRouter from "routers/index";
import Smartlook from 'smartlook-client';

function App() {
  useEffect(() => {
    const isProduction = process.env.REACT_APP_ENV === 'production';
    const isNotLocalhost = window.location.hostname !== 'localhost';
    
    if (isProduction && isNotLocalhost) {
      Smartlook.init(process.env.REACT_APP_SMARTLOOK_API_KEY || '');
    }
  }, []);

  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200 min-h-screen">
      <MyRouter />
    </div>
  );
}

export default App;
