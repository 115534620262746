import { AxiosResponse } from "axios";
import axiosInstance from "utils/setAuthorizationHeader";

export enum NotificationType {
    CONNECTION_REQUEST = 'connection_request',
    MATCH_FOUND = 'match_found',
    NEW_COMMUNITY_MEMBER = 'new_community_member',
    NEW_MESSAGE = 'new_message',
    FRIEND_REQUEST = 'friend_request',
    ACCEPTED_FRIEND_REQUEST = 'accepted_friend_request',
    POST = 'post',
    COMMENT = 'comment',
    LIKE = 'like',
    SHARE = 'share',
    TAG = 'tag',
    GROUP = 'group',
    EVENT = 'event',
    // Add other notification types here
}

export enum NotificationStatus {
    VIEWED = 'viewed',
    REMOVED = 'removed',
    UNOPENED = 'unopened', // Add the new status here
}

export interface INotification {
    id: string;
    type: NotificationType;
    data: any;
    status: NotificationStatus;
    createdAt: string;
}

export async function fetchNotificationsFromAPI(): Promise<AxiosResponse<INotification[]>> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/notifications`, { withCredentials: true })
}

export async function fetchUnreadNotifications(): Promise<AxiosResponse<boolean>> {
    return axiosInstance.get(`${process.env.REACT_APP_API_URL}/notifications/unread`, { withCredentials: true })
}

export async function removeNotification(id: string): Promise<AxiosResponse<boolean>> {
    return axiosInstance.delete(`${process.env.REACT_APP_API_URL}/notifications/${id}`, { withCredentials: true })
}
