import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { HandshakeRequest, getHandshakes, approveConnection, declineConnection } from 'api/connections';
import Heading from 'components/Heading/Heading';
import NoResults from 'components/NoResults/NoResults';
import FooterNav from 'components/FooterNav';
import BlueLoader from 'shared/Loader/BlueLoader';
import { useNavigate } from 'react-router-dom';

const HandshakePage: React.FC = () => {
  const [requests, setRequests] = useState<HandshakeRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchHandshakes = async () => {
    try {
      const data = await getHandshakes();
      setRequests(data);
    } catch (error) {
      toast.error('Failed to fetch handshake requests');
    } finally {
      setLoading(false);
    }
  };

  const handleRequest = async (requestId: string, action: 'accept' | 'reject') => {
    try {
      if (action === 'accept') {
        await approveConnection(requestId);
        toast.success(`Request ${action}ed successfully`,{theme: 'light'});
        navigate(`/connections`);
      } else {
        await declineConnection(requestId);
        toast.success(`Request ${action}ed successfully`,{theme: 'light'});
        fetchHandshakes();
      }
    } catch (error) {
      toast.error(`Failed to ${action} request`);
    }
  };

  useEffect(() => {
    fetchHandshakes();
  }, []);

  if (loading) {
    return <BlueLoader />;
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <Heading desc="This is the handshake requests page">Handshake Requests</Heading>
      
      {requests.length === 0 ? (
        <NoResults 
          message="No pending connection requests" 
          icon="🤝"
        />
      ) : (
        <div className="space-y-4">
          {requests.map((request) => (
            <div 
              key={request.connectionId}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-200"
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-4">
                  <div className="relative">
                    {request.sender.avatar ? (
                      <img 
                        src={request.sender.avatar} 
                        alt={request.sender.name}
                        className="w-16 h-16 rounded-full object-cover"
                      />
                    ) : (
                      <div className="w-16 h-16 rounded-full bg-gray-200 flex items-center justify-center">
                        <span className="text-gray-500 text-xl">
                          {request.sender.name.charAt(0).toUpperCase()}
                        </span>
                      </div>
                    )}
                  </div>
                  <div>
                    <h3 className="font-semibold text-lg">{request.sender.name}</h3>
                    <p className="text-sm text-gray-500">
                      Sent {new Date(request.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                </div>
                
                {request.status === 'pending' ? (
                  <div className="flex space-x-3">
                    <button
                      onClick={() => handleRequest(request.connectionId, 'accept')}
                      className="px-4 py-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition-colors duration-200"
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => handleRequest(request.connectionId, 'reject')}
                      className="px-4 py-2 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors duration-200"
                    >
                      Decline
                    </button>
                  </div>
                ) : (
                  <span className={`px-3 py-1 rounded-full text-sm ${
                    request.status === 'accepted' 
                      ? 'bg-green-100 text-green-800' 
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      <FooterNav />
    </div>
  );
};

export default observer(HandshakePage); 