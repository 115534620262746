import { useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import CommonLayout from 'containers/AccountPage/CommonLayout';
import { interests } from 'containers/OnBoarding/OnBoardingStep4';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

function PageMyInterest({ className = "" }) {
    const { currentUser } = authStore;

    // Local state to manage interests
    const [selectedInterests, setSelectedInterests] = useState<string[]>(currentUser?.profile?.interests || []);
    const [loading, setLoading] = useState<boolean>(false);

    function classNames(...classes: string[]): string {
        return classes.filter(Boolean).join(' ');
    }

    const toggleTopic = (interest: string) => {
        setSelectedInterests((prevInterests) =>
            prevInterests.includes(interest)
                ? prevInterests.filter((id) => id !== interest)
                : [...prevInterests, interest]
        );
    };

    const saveInterests = () => {
        if (currentUser?.id) {
            setLoading(true);
            authStore.updateProfile({ id: currentUser.id, interests: selectedInterests });
            setLoading(false);
        }
    };

    return (
        <CommonLayout>
            <div className="space-y-6 sm:space-y-8">

                <h2 className="text-3xl font-semibold">Set up your interests</h2>
                <p className="text-center text-gray-500 mb-6">
                    Choose your interests to help us personalize your experience.
                    Our AI uses this information to suggest the best matches and activities tailored to your preferences.
                </p>

                <div className="w-full max-w-md mx-auto text-white rounded-lg">
                    {Object.keys(interests).map((interset) => (
                        <Disclosure key={interset}>
                            {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex justify-between w-full px-4 py-4 mt-2 text-sm font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75">
                                        <span>{`${interests[interset].emoji} ${interset}`}</span>
                                        {open ? (
                                            <ChevronUpIcon className="w-5 h-5 text-white" />
                                        ) : (
                                            <ChevronDownIcon className="w-5 h-5 text-white" />
                                        )}
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-300">
                                        <div className="flex flex-wrap gap-2">
                                            {interests[interset].topics.map((topic) => (
                                                <button
                                                    key={topic}
                                                    className={classNames(
                                                        'px-3 py-1 border rounded-full text-sm',
                                                        selectedInterests.includes(topic)
                                                            ? 'bg-blue-500 text-white border-blue-500'
                                                            : 'bg-gray-800 text-white border-gray-600 hover:bg-gray-700'
                                                    )}
                                                    onClick={() => toggleTopic(topic)}
                                                >
                                                    {topic}
                                                </button>
                                            ))}
                                        </div>
                                    </Disclosure.Panel>
                                </>
                            )}
                        </Disclosure>
                    ))}
                </div>

                {/* Save Button */}
                <div className="text-center mt-4">
                    <ButtonPrimary
                        loading={loading}
                        className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                        onClick={saveInterests}
                    >
                        Save Interests
                    </ButtonPrimary>
                </div>
            </div>
        </CommonLayout>
    );
}

export default observer(PageMyInterest);
