import { Transition, Dialog } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon, LinkedinShareButton, LinkedinIcon, EmailShareButton, EmailIcon } from "react-share";

interface LikeSaveBtnsProps {
  shareUrl?: string;
  title?: string;
  description?: string;
  startDate?: string;
  location?: string;
}

const LikeSaveBtns: React.FC<LikeSaveBtnsProps> = ({ shareUrl, title, description = '', startDate = '', location = '' }) => {
  const [isOpen, setIsOpen] = useState(false);


  const messageBody = `
Hey! Check out this event:

${title}
${startDate ? `When: ${startDate}` : ''}
${location ? `Where: ${location}` : ''}
${description ? `\n${description}\n` : ''}
  `.trim();


  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer" onClick={() => title && shareUrl && setIsOpen(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Share</span>
        </span>
        <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
          <span className="hidden sm:block ml-2.5">Save</span>
        </span>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-800 p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-neutral-900 dark:text-neutral-100"
                  >
                    Share via
                  </Dialog.Title>

                  <div className="mt-4 flex justify-center space-x-4">
                    <FacebookShareButton
                      url={shareUrl || ''}
                      hashtag="#MeetWithEvent"
                    >
                      <FacebookIcon size={40} round />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={shareUrl || ''}
                      title={messageBody}
                      via="MeetWith"
                      hashtags={["MeetWithEvent", "Community"]}
                      related={["MeetWith"]}
                    >
                      <TwitterIcon size={40} round />
                    </TwitterShareButton>

                    <WhatsappShareButton
                      url={shareUrl || ''}
                      title={`${title}\n\n${description}\n\n${startDate ? `When: ${startDate}\n` : ''}${location ? `Where: ${location}` : ''}`}
                      separator=">>> "
                    >
                      <WhatsappIcon size={40} round />
                    </WhatsappShareButton>

                    <LinkedinShareButton
                      url={shareUrl || ''}
                      title={title}
                      summary={`${description}\n${startDate ? `When: ${startDate}` : ''}\n${location ? `Where: ${location}` : ''}`}
                      source="MeetWith"
                    >
                      <LinkedinIcon size={40} round />
                    </LinkedinShareButton>

                    <EmailShareButton
                      url={shareUrl || ''}
                      subject={`Join this event: ${title}`}
                      body={messageBody}
                      separator=">>> "
                    >
                      <EmailIcon size={40} round />
                    </EmailShareButton>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent bg-neutral-100 dark:bg-neutral-700 px-4 py-2 text-sm font-medium text-neutral-900 dark:text-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-neutral-500 focus-visible:ring-offset-2"
                      onClick={() => setIsOpen(false)}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default LikeSaveBtns;
