import { ActivityResponse } from "interface/activity";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridActivityBox from "components/SectionGridActivityBox/SectionGridActivityBox";

interface ActivitiesSectionProps {
  activities: ActivityResponse[];
}

export const ActivitiesSection = ({ activities }: ActivitiesSectionProps) => {
  return (
    <div className="relative py-16">
      <BackgroundSection />
      <SectionGridActivityBox
        boxCard="box2"
        activities={activities}
        heading="Join Activities that Spark Growth and Connection"
        desc="Connect, Explore, and Grow with New Experiences"
      />
    </div>
  );
}; 