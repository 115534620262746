import { Dialog, Transition } from '@headlessui/react';
import Heading from 'components/Heading/Heading';
import { observer } from 'mobx-react';
import { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import AITabFilters from './AITabFilters';
import ChatArea from './ChatArea';
import { FilterProvider } from './FilterContext';

interface Match {
    id: string;
    fullName: string;
    industry: string;
    role: string;
    country: string;
    companyName: string;
    percentage: number;
    avatarUrl: string;
    userId: string;
    desc: string;
}

const AICommunityMatchPage = () => {
    const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
    const navigate = useNavigate(); // For navigating to user profile
    
    const searchParams = new URLSearchParams(window.location.search);
    const community_id = searchParams.get('community_id');

    const handleMatchClick = (match: Match) => {
        setSelectedMatch(match);
    };

    const handleCloseModal = () => {
        setSelectedMatch(null);
    };

    const goToProfile = (id: string) => {
        navigate(`/user/${id}`); // Navigate to the user's page
    };

    return (
        <div className="container p-0">
            <Heading className="ml-4 mt-4" desc="Discover business travelers and connect effortlessly.">
                Use AI for Finding Matches
            </Heading>
            <div className="mb-[58px] p-4">
                <FilterProvider>
                    <AITabFilters />
                    <ChatArea onMatchClick={handleMatchClick} communityId={community_id || ''} />
                </FilterProvider>
            </div>
            {selectedMatch && (
                <Transition appear show={!!selectedMatch} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex items-center justify-center min-h-full p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <div className="flex items-start space-x-4">
                                            <Avatar
                                                imgUrl={selectedMatch.avatarUrl}
                                                userName={selectedMatch.fullName}
                                                sizeClass="w-16 h-16 rounded-full"
                                            />
                                            <div className="flex-1 min-w-0">
                                                <Dialog.Title as="h2" className="text-lg font-semibold">
                                                    {selectedMatch.fullName}
                                                </Dialog.Title>
                                                <p className="text-sm text-gray-500">{selectedMatch.industry}</p>
                                                <p className="text-sm text-gray-600">{selectedMatch.role}</p>
                                                <p className="text-sm text-gray-600">{selectedMatch.companyName}</p>
                                                <p className="text-sm text-gray-500">{selectedMatch.country}</p>
                                                <p className="text-sm font-semibold text-green-500">
                                                    {selectedMatch.percentage}% Match
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mt-4 bg-blue-100 border border-blue-500 p-4 rounded-lg">
                                            <span className="inline-block bg-blue-500 text-white text-xs font-bold px-2 py-1 rounded-full mb-2">
                                                ✨ AI Result
                                            </span>
                                            <p className="text-sm text-gray-500">{selectedMatch.desc}</p>
                                        </div>
                                        <div className="mt-6 text-right">
                                            <ButtonPrimary onClick={() => goToProfile(selectedMatch.userId)}>
                                                Go to Profile
                                            </ButtonPrimary>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            )}
        </div>
    );
};

export default observer(AICommunityMatchPage);