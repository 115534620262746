import React from 'react';

interface NoResultsProps {
  message?: string;
  icon?: React.ReactNode;
}

const NoResults: React.FC<NoResultsProps> = ({ 
  message = 'No results found',
  icon = '🔍'
}) => {
  return (
    <div className="flex flex-col items-center justify-center py-12">
      <div className="text-4xl mb-4">
        {icon}
      </div>
      <p className="text-gray-500 text-lg text-center">
        {message}
      </p>
    </div>
  );
};

export default NoResults; 