import moment from 'moment-timezone'

export const convertToLocal = (dateStr: string | null | undefined, timezoneStr: string = Intl.DateTimeFormat().resolvedOptions().timeZone, format: string = 'lll') => {
    if (!dateStr) {
        return ''
    }

    return moment.tz(dateStr, timezoneStr).format(format);
}

export const formatDateRange = (startDate: string, endDate: string, timezoneStr: string | null = null) => {

    const start = timezoneStr ? moment.tz(startDate, timezoneStr) : moment(startDate)
    const end = timezoneStr ? moment.tz(endDate, timezoneStr) : moment(endDate)

    // Check if the start and end dates are in the same year
    if (start.year() === end.year()) {
        // Check if the start and end dates are in the same month
        if (start.month() === end.month()) {
            return `${start.format('MMM D')} - ${end.format('D, YYYY')}`;
        } else {
            return `${start.format('MMM D')} - ${end.format('MMM D, YYYY')}`;
        }
    } else {
        // Different years
        return `${start.format('MMM D, YYYY')} - ${end.format('MMM D, YYYY')}`;
    }
}

export function formatDuration(seconds: number) {
    const duration = moment.duration(seconds, 'seconds');

    // Example formatting
    const hours = duration.hours();
    const minutes = duration.minutes();
    const secs = duration.seconds();

    // Construct a string representing the duration. 
    // This example formats it as "2 hours 15 minutes 30 seconds". Adjust formatting as needed.
    let durationStr = "";
    if (hours > 0) durationStr += `${hours} hour${hours > 1 ? 's' : ''} `;
    if (minutes > 0) durationStr += `${minutes} minute${minutes > 1 ? 's' : ''} `;
    if (secs > 0 || durationStr === "") durationStr += `${secs} second${secs !== 1 ? 's' : ''}`;

    return durationStr.trim();
}

/**
 * Converts a UTC date string to a local date and time.
 * @param {string} utcDateStr - The UTC date string.
 * @param {string} [format='YYYY-MM-DD HH:mm:ss'] - The desired output format (optional).
 * @param {string} [timezone=moment.tz.guess()] - The target timezone (optional).
 * @returns {string} The formatted local date and time.
 */
export function convertUTCToLocal(utcDateStr: string | Date, format = 'lll', timezone = moment.tz.guess()) {
    return moment.tz(utcDateStr, timezone).format(format);
}