import { TripDetailsResponse } from "interface/trip.interface";
import { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import BadgeScore from "shared/BadgeScore/BadgeScore";
import { formatDateRange } from "utils/dates";

export interface CardTripBoxProps {
  className?: string;
  tripDetails: TripDetailsResponse;
  index?: number;
}

const CardTripBox: FC<CardTripBoxProps> = ({
  className = "",
  tripDetails: trip,
  index,
}) => {
  const { id, displayName, avatar, country, startDate, endDate, industry, matchScore, companyName, role } = trip;

  const getRingColor = (score: number) => {
    if (score >= 80) return "ring-purple-200 dark:ring-purple-900";
    if (score >= 60) return "ring-green-200 dark:ring-green-900";
    if (score >= 30) return "ring-blue-200 dark:ring-blue-900";
    return "ring-gray-200 dark:ring-gray-800";
  };

  const getBorderColor = (score: number) => {
    if (score >= 80) return "border-purple-100 dark:border-purple-900";
    if (score >= 60) return "border-green-100 dark:border-green-900";
    if (score >= 30) return "border-blue-100 dark:border-blue-900";
    return "border-gray-200 dark:border-gray-800";
  };

  return (
    <Link
      to={`/user/${id}`}
      className={`
        nc-CardTripBox relative 
        flex flex-col items-center 
        p-6 
        h-[280px] w-[240px]
        rounded-2xl
        transition-all duration-300
        bg-white dark:bg-neutral-900
        hover:shadow-xl hover:scale-105
        border ${getBorderColor(matchScore || 0)}
        ${className}
      `}
    >
      {/* Top Section with Badge and Avatar */}
      <div className="relative w-full flex justify-center">
        {index && (
          <BadgeScore
            className="absolute -top-3 -right-3 z-10"
            name=""
            matchScore={matchScore}
          />
        )}
        <Avatar
          sizeClass="w-24 h-24 text-2xl"
          radius="rounded-full"
          imgUrl={avatar}
          userName={displayName}
          containerClassName={`ring-4 ${getRingColor(matchScore || 0)} shadow-lg`}
        />
      </div>

      {/* User Info Section */}
      <div className="mt-4 text-center w-full">
        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-100">
          {displayName}
        </h2>
        <div className="mt-1 text-sm text-neutral-500 dark:text-neutral-400 space-y-1">
          <p className="truncate">{role}</p>
          {companyName && (
            <p className="truncate text-xs">
              @ {companyName}
            </p>
          )}
          <p className="font-medium text-neutral-700 dark:text-neutral-300">{industry}</p>
        </div>
      </div>

      {/* Location and Date Section */}
      <div className="mt-auto w-full">
        <div className="text-xs text-neutral-500 dark:text-neutral-400">
          <div className="flex items-center justify-center gap-1">
            <span>{country}</span>
          </div>
          <p className="text-center mt-1">{formatDateRange(startDate, endDate)}</p>
        </div>
      </div>
    </Link>
  );
};

export default CardTripBox;
