import { Tab } from "@headlessui/react";
import { ConnectionResponse, approveConnection, cancelConnection, declineConnection, getConnection, requestConnection } from "api/connections";
import { Profile, UserProfileDTO } from "api/dto/get-user-data-by-id.dto";
import { getMemberDataById } from "api/user";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import FooterNav from "components/FooterNav";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import { PostDataType } from "data/types";
import { observer } from "mobx-react";
import { FC, Fragment, useEffect, useState } from "react";
import CountUp from "react-countup";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useParams } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcImage from "shared/NcImage/NcImage";
import ConnectionButtons from "./ConnectionSection";
import UserTripCard from "./UserTripCard";
import BadgeScore from "shared/BadgeScore/BadgeScore";
import { authStore } from "stores/AuthStore";
import SocialsList from "shared/SocialsList/SocialsList";
import ShowMoreText from "react-show-more-text";

export interface UserPageProps {
  className?: string;
}

interface SocialType {
  name: string;
  icon: string;
  href: string;
}

function buildSocials(profile: Profile): SocialType[] {
  const socialPlatforms = [
    { name: "Facebook", icon: "lab la-facebook-square", urlKey: "facebookUrl" },
    { name: "Twitter", icon: "lab la-twitter", urlKey: "twitterUrl" },
    { name: "Instagram", icon: "lab la-instagram", urlKey: "instagramUrl" },
    { name: "LinkedIn", icon: "lab la-linkedin", urlKey: "linkedinUrl" },
  ];

  const socials = socialPlatforms
    .map((platform) => ({
      name: platform.name,
      icon: platform.icon,
      href: (profile[platform.urlKey as keyof Profile] as string) || "#",
    }))
    .filter((social) => social.href !== "#"); // Remove entries without a valid URL

  // Return empty array if no valid social links are present
  return socials.length > 0 ? socials : [];
}


const UserPage: FC<UserPageProps> = ({ className = "" }) => {
  let [categories] = useState(["Stays", "Experiences", "Car for rent"]);
  const { userId } = useParams<{ userId: string }>(); // Extract userId from URL
  const [userData, setUserData] = useState<UserProfileDTO>(); // State to hold user data
  const [connection, setConnection] = useState<ConnectionResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const miles = 15000; // Example miles
  const tripsCount = 5; // Example trip count
  const matchesCount = 20; // Example match count
  // Fetch user data from backend
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        console.log("Fetching user data for user ID:", userId);

        const response = await getMemberDataById(userId);
        console.log("Response:", response);

        setUserData(response.data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const fetchUserConnection = async () => {
      try {
        const data = await getConnection(userId ?? '');
        setConnection(data);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
      }
    };

    fetchUserConnection();
  }, []);

  async function handleHandshake() {
    setIsLoading(true);
    // Implement handshake logic
    console.log('Handshake request sent');
    const response = await requestConnection(userData?.id ?? '');
    setConnection(response);
    setConnection(response);
    setIsLoading(false);
  }

  async function handleCancel() {
    setIsLoading(true);
    console.log('Handshake request canceled');
    await cancelConnection(connection?.id ?? '');
    setConnection(null);
    setIsLoading(false);
  }

  async function handleConfirm() {
    setIsLoading(true);
    console.log('Handshake request confirmed');
    const response = await approveConnection(connection?.id ?? '');
    setConnection({ ...connection, ...response });
    setIsLoading(false);
  }

  async function handleDecline() {
    setIsLoading(true);
    console.log('Handshake request declined');
    const response = await declineConnection(connection?.id ?? '');
    setConnection(response);
    setIsLoading(false);
  }

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          sizeClass="w-28 h-28"
          imgUrl={userData?.profile?.avatar}
          userName={userData?.profile?.fullName}
        />

        <div className="text-center flex flex-col items-center">
          <h2 className="text-3xl font-semibold">{userData?.profile?.fullName}</h2>
          <p className="text-gray-500 dark:text-gray-400 text-sm">
            {userData?.profile?.role}{" "}
            {userData?.profile?.companyName && (
              <span>
                at <span className="font-semibold">{userData?.profile?.companyName}</span>
              </span>
            )}
          </p>
          <p className="text-gray-500 dark:text-gray-400 text-sm">
            {userData?.profile?.industry}
          </p>

          <p className="text-gray-500 dark:text-gray-400 text-xs">
            {userData?.profile?.country}
          </p>
        </div>

        <p className="text-neutral-500 dark:text-neutral-400">
        <ShowMoreText lines={5}>
          {userData?.profile?.bio}
        </ShowMoreText>
        </p>

        <SocialsList
          className="!space-x-3"
          itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          socials={userData?.profile ? buildSocials(userData.profile) : []}
        />

        <div className="flex items-center justify-center space-x-4">
          <ConnectionButtons
            connection={connection}
            user={authStore.currentUser}
            onHandshake={handleHandshake}
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            onDecline={handleDecline}
            isLoading={isLoading}
          />
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        <div className="mt-6">
          <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
            Interests
          </h2>
          <div className="flex flex-wrap gap-1 mt-4">
            {userData?.profile?.interests?.map((interest, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-blue-100 text-blue-600 text-sm font-medium rounded-full dark:bg-blue-900 dark:text-blue-300"
              >
                {interest}
              </span>
            ))}
          </div>
        </div>

        <div className="mt-6">
          <h2 className="text-lg font-semibold text-gray-800 dark:text-white">
            Languages
          </h2>
          <div className="flex flex-wrap gap-2 mt-4">
            {userData?.profile?.languages?.map((language, index) => (
              <span
                key={index}
                className="px-3 py-1 bg-green-100 text-green-600 text-sm font-medium rounded-full dark:bg-green-900 dark:text-green-300"
              >
                {language}
              </span>
            ))}
          </div>
        </div>

      </div>
    );
  };
  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">{userData?.profile?.fullName}'s listings</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">Previous business trip and stays.</span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        <div>
          <Tab.Group>
            <Tab.List className="flex space-x-1 overflow-x-auto">
              {categories.map((item) => (
                <Tab key={item} as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${selected
                        ? "bg-secondary-900 text-secondary-50 "
                        : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        } `}
                    >
                      {item}
                    </button>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_STAY_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <StayCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 4).map(
                    (stay) => (
                      <ExperiencesCard key={stay.id} data={stay} />
                    )
                  )}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
              <Tab.Panel className="">
                <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {DEMO_CAR_LISTINGS.filter((_, i) => i < 4).map((stay) => (
                    <CarCard key={stay.id} data={stay} />
                  ))}
                </div>
                <div className="flex mt-11 justify-center items-center">
                  <ButtonSecondary>Show me more</ButtonSecondary>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing hasListingTitle className="pb-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <CommentListing hasListingTitle className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  const userStats = ({ miles, tripsCount, matchesCount }: { miles: number, tripsCount: number, matchesCount: number }) => {
    return (
      <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
        <div className="flex items-center space-x-3">
          <i className="las la-plane text-2xl"></i>
          <CountUp
            start={0}
            end={miles}
            duration={3}
            delay={0}
          >
            {({ countUpRef }) => (
              <span ref={countUpRef}>
                <span className="hidden sm:inline-block">miles</span>
              </span>
            )}
          </CountUp>

        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-suitcase text-2xl"></i>
          <CountUp
            start={0}
            end={tripsCount}
            duration={3}
            delay={0}
          >
            {({ countUpRef }) => (
              <span ref={countUpRef}>
                <span className="hidden sm:inline-block">trips</span>
              </span>
            )}
          </CountUp>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-handshake text-2xl"></i>
          <CountUp
            start={0}
            end={matchesCount}
            duration={3}
            delay={0}
          >
            {({ countUpRef }) => (
              <span ref={countUpRef}>
                <span className="hidden sm:inline-block">matches</span>
              </span>
            )}
          </CountUp>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AuthorPage ${className} mb-20`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Meet With - User Page</title>
      </Helmet>
      <main className="container mt-4 flex flex-col">
        {/* <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row"> */}
        <div className="block flex-grow mb-20 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        {userData?.trips?.length ? <div className="lg:mt-16 ">
          <h2 className="text-3xl font-semibold">User Trips</h2>
          <div className={`mt-10 mb-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-5 sm:gap-6 md:gap-8`}>
            {userData?.trips.map((item, i) => (
              <UserTripCard key={i} trip={item} />
            ))}
          </div>
        </div> : null}

        {/* <div className="w-full lg:w-3/5 xl:w-2/3 flex-shrink-0"> */}
        {/* {renderSection1()} */}
        {/* {renderSection2()} */}
        {/* </div> */}
      </main>
      <FooterNav />
    </div>
  );
};

export default observer(UserPage)
