import { fetchNotificationsFromAPI, fetchUnreadNotifications, INotification, removeNotification } from "api/notifications";
import { makeAutoObservable } from "mobx";

class NotificationStore {
    notifications: INotification[] = [];
    hasUnreadMessages: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async checkForUnreadMessages() {
        // Replace this with your actual API call
        const { data: fetchedNotifications } = await fetchUnreadNotifications();
        this.hasUnreadMessages = fetchedNotifications;
    }

    // Function to pull notifications
    async pullNotifications() {
        try {
            // Replace this with your actual API call
            const { data: fetchedNotifications } = await fetchNotificationsFromAPI();
            this.setNotifications(fetchedNotifications);
            // Since this is a MobX store and it's marked as observable, 
            // any UI components observing this.notifications will automatically re-render
            return true;
        } catch (error) {
            console.error('Failed to pull notifications:', error);
            return false;
        }
    }

    setNotifications(notifications: INotification[]) {
        this.notifications = notifications;
    }

    // Function to remove a notification
    async removeNotification(id: string) {
        const isDeleted = await removeNotification(id);
        if (isDeleted) {
            this.notifications = this.notifications.filter(notification => notification.id !== id);
        }
    }

    // Function to set a notification as seen
    setAsSeen(id: string) {
        this.notifications = this.notifications.map(notification =>
            notification.id === id ? { ...notification, seen: true } : notification
        );
    }
}

// Instantiate the store
export const notificationStore = new NotificationStore();
