import { getMetadata } from "api/metadata";
import { MemberRole } from "interface/communities";
import { makeAutoObservable, runInAction } from "mobx";

interface Industry {
    id: string;
    name: string;
}

interface Role {
    id: string;
    name: string;
}

interface Communities {
    id: string;
    name: string;
    community: Community;
    role: MemberRole;
}
interface Community {
    id: string;
}

class MetadataStore {
    industries: Industry[] = [];
    roles: Role[] = [];
    communities: Communities[] = [];
    loading: boolean = false;
    error: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    /**
     * Fetch metadata and store it in the MobX store
     */
    async fetchMetadata() {
        this.loading = true;
        this.error = null;

        try {
            const response = await getMetadata();
            const { industries, roles, getMyCommunities } = response.data;

            runInAction(() => {
                this.industries = industries;
                this.roles = roles;
                this.communities = getMyCommunities;
                this.loading = false;
            });
        } catch (error: any) {
            runInAction(() => {
                this.error = error.message || "Failed to fetch metadata";
                this.loading = false;
            });
        }
    }

    /**
     * Clear the metadata (if needed for reloading)
     */
    clearMetadata() {
        this.industries = [];
        this.roles = [];
        this.communities = [];
    }
}

const metadataStore = new MetadataStore();
export default metadataStore;