import { Dialog } from "@headlessui/react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { createBulletin } from "api/bulletins";
import { BulletinResponse } from "interface/communities";
import React, { FC, useState } from "react";
import { toast } from "react-toastify"; // Import Toastify
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface DigitalBulletinHeadingProps {
  className?: string;
  title: string;
  communityId: string;
  onBulletinCreated?: (bulletin: BulletinResponse) => void;
}

const DigitalBulletinHeading: FC<DigitalBulletinHeadingProps> = ({
  className = "",
  title,
  communityId,
  onBulletinCreated
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({ title: "", content: "", communityId: "" });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setFormData({ title: "", content: "", communityId: "" });
    setIsModalOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Basic validation
    if (!formData.title.trim() || !formData.content.trim()) {
      toast.error("Both title and description are required.", { theme: "light" });
      return;
    }

    try {
      setIsSubmitting(true);
      formData.communityId = communityId;
      const response = await createBulletin(formData);
      toast.success("Announcement posted successfully!", { theme: "light" });
      if (onBulletinCreated) {
        onBulletinCreated(response.data);
      }
      closeModal();
    } catch (error) {
      console.error("Error posting announcement:", error);
      toast.error("Failed to post the announcement. Please try again.", { theme: "light" });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className={`nc-DigitalBulletinHeading flex items-center justify-between p-4 xl:p-5 border-b border-neutral-200 dark:border-neutral-700 ${className}`}
      data-nc-id="DigitalBulletinHeading"
    >
      <h2 className="text-lg text-neutral-900 dark:text-neutral-100 font-semibold flex-grow">
        {title}
      </h2>
      <ButtonPrimary
        className="flex-shrink-0 dark:text-primary-500 font-semibold text-sm"
        onClick={openModal}
      >
        <PlusIcon className="w-5 h-5" />
      </ButtonPrimary>
      <Dialog open={isModalOpen} onClose={closeModal} className="fixed z-10 inset-0 overflow-y-auto">
        <div className="flex items-center justify-center min-h-screen px-4">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6 z-20">
            <Dialog.Title className="text-lg font-bold">Post Announcement</Dialog.Title>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Title</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Enter title"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Description</label>
                <textarea
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  className="mt-1 w-full border border-gray-300 rounded-md px-3 py-2 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                  rows={4}
                  placeholder="Enter description"
                />
              </div>
              <div className="flex justify-end">
                <ButtonSecondary
                  type="button"
                  onClick={closeModal}
                  className="mr-2 px-4 py-2 bg-gray-200 hover:bg-gray-300"
                >
                  Cancel
                </ButtonSecondary>
                <ButtonPrimary
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 bg-primary-500 text-white hover:bg-primary-600"
                >
                  {isSubmitting ? "Posting..." : "Post"}
                </ButtonPrimary>
              </div>
            </form>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DigitalBulletinHeading;
