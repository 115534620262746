import MoonLoaderComponent from "components/Loaders/MoonLoader";
import SectionEventsSlider from "components/SectionEventsSlider/SectionEventsSlider";
import eventStore from "stores/EventsStore";

interface EventsSectionProps {
  currentTripId?: string;
}

export const EventsSection = ({ currentTripId }: EventsSectionProps) => {
  return eventStore.isLoading ? (
    <MoonLoaderComponent />
  ) : eventStore.events.length ? (
    <SectionEventsSlider
      sourceId={currentTripId || 'localEvents'}
      events={eventStore.events}
      uniqueClassName="events"
    />
  ) : null; 
};
