import { ChatBubbleBottomCenterTextIcon } from "@heroicons/react/24/outline";
import { observer } from "mobx-react";
import { FC, useEffect } from "react";
import Logo from "shared/Logo/Logo";
import { notificationStore } from "stores/NotificationsStore";
import NotifyDropdown from "./NotifyDropdown";
import { authStore } from "stores/AuthStore";
import AvatarDropdown from "./AvatarDropdown";
import { useNavigate } from "react-router-dom";
import { chatStore } from "stores/ChatStore";

export interface MainNav1Props {
  className?: string;
  showChatIcon?: boolean;
  showInfoIcon?: boolean;
  showNotificationIcon?: boolean;
}

const MainNav1: FC<MainNav1Props> = observer(({ className = "", showChatIcon = true, showInfoIcon = true, showNotificationIcon = true }) => {
  const { currentUser } = authStore
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      
      notificationStore.checkForUnreadMessages()
    }
  }, [currentUser]);

  return (
    <div className={`nc-MainNav1 relative z-10  ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex items-center justify-between border-b border-neutral-200 dark:border-neutral-700">
        {/* Logo on the left */}
        <div className="flex justify-start">
          <Logo className="w-40" />
        </div>

        {/* Center placeholder */}
        <div className="flex-1 hidden md:block"></div>

        {/* Icons on the right */}
        <div className="relative z-10 flex-1 flex items-center justify-end text-neutral-700 dark:text-neutral-100">
          <div className="flex justify-end items-center space-x-1 pr-4">
            {showChatIcon && (
              <button 
                onClick={() => navigate('/chats')}
                className="group p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative"
              >
                <ChatBubbleBottomCenterTextIcon className="w-6 h-6" />
                {chatStore.hasUnreadMessages && (
                  <span className="w-2 h-2 bg-blue-500 absolute top-2 right-2 rounded-full"></span>
                )}
              </button>
            )}
            {showNotificationIcon && (
              <div className="pr-1.5">
                <NotifyDropdown className="-ml-2 xl:-ml-1" />
              </div>
            )}
            <div className="hidden md:block">
              <AvatarDropdown imgUrl={authStore?.currentUser?.profile?.avatar || ''} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MainNav1;
