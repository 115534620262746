import { Popover, Transition } from "@headlessui/react";
import { XMarkIcon } from '@heroicons/react/24/outline';
import { NotificationType } from "api/notifications";
import { INotification } from "api/notifications";
import { observer } from "mobx-react";
import { FC, Fragment, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Avatar from "shared/Avatar/Avatar";
import { Spinner } from "shared/Loader/Spinner";
import { notificationStore } from "stores/NotificationsStore";
import { convertUTCToLocal } from "utils/dates";

interface Props {
  className?: string;
}

const getNotificationUrl = (notification: INotification) => {
  switch (notification.type) {
    case NotificationType.CONNECTION_REQUEST:
      return `/user/${notification.data.senderId}`;
    // case NotificationType.FRIEND_REQUEST:
    //   return `/friends`;
    // case NotificationType.EVENT:
    //   return `/meetings/${notification.data.meetingId}`;
    // case NotificationType.CONNECTION_REQUEST:
    //   return `/profile/${notification.data.userId}`;
    default:
      return '#';
  }
};

interface NotificationMessage {
  text: string;
  imageUrl: string;
  time: string;
  subtitle?: string;
  redirectUrl?: string;
}

abstract class NotificationMessageBuilder {
  protected notification: INotification;

  constructor(notification: INotification) {
    this.notification = notification;
  }

  abstract buildMessage(): NotificationMessage;

  protected convertTime(): string {
    return convertUTCToLocal(this.notification.data.time);
  }
}

class ConnectionRequestMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { senderId, senderName, imageUrl } = this.notification.data;

    return {
      text: `${senderName} is interested to connect with you.`,
      imageUrl,
      time: this.convertTime(),
      redirectUrl: `/user/${senderId}`,
    };
  }
}

class MatchFoundMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { text, imageUrl, subtitle } = this.notification.data;
    return {
      text: `Match Found: ${text}`,
      imageUrl,
      time: this.convertTime(),
      subtitle: `Matched with: ${subtitle}`,
    };
  }
}

class NewCommunityMemberMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { communityName, newMemberImageUrl, newMemberName, newMemberId } = this.notification.data;
    
    return {
      text: `New user <strong>${newMemberName}</strong> joined the community <strong>${communityName}</strong>`,
      imageUrl: newMemberImageUrl,
      time: this.convertTime(),
      subtitle: `Welcome: ${newMemberName}`,
      redirectUrl: `/user/${newMemberId}`,
    };
  }
}

class DefaultMessageBuilder extends NotificationMessageBuilder {
  buildMessage(): NotificationMessage {
    const { text, imageUrl, subtitle } = this.notification.data;
    return {
      text,
      imageUrl,
      time: this.convertTime(),
      subtitle,
    };
  }
}

const buildNotificationMessage = (notification: INotification): NotificationMessage => {
  let builder: NotificationMessageBuilder;

  switch (notification.type) {
    case NotificationType.CONNECTION_REQUEST:
      builder = new ConnectionRequestMessageBuilder(notification);
      break;
    case NotificationType.MATCH_FOUND:
      builder = new MatchFoundMessageBuilder(notification);
      break;
    case NotificationType.NEW_COMMUNITY_MEMBER:
      builder = new NewCommunityMemberMessageBuilder(notification);
      break;
    default:
      builder = new DefaultMessageBuilder(notification);
  }

  return builder.buildMessage();
};




const NotifyDropdown: FC<Props> = observer(({ className = "" }) => {

  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleRemoveNotification = async (notificationId: string, e: React.MouseEvent) => {
    e.preventDefault(); // Prevent triggering the parent anchor click
    e.stopPropagation();
    try {
      await notificationStore.removeNotification(notificationId);
    } catch (error) {
      console.error('Failed to remove notification:', error);
    }
  };

  const handleButtonClick = async () => {
    setIsLoading(true)
    await notificationStore.pullNotifications();
    setIsLoading(false)
  };

  useEffect(() => {
    if (notificationStore.hasUnreadMessages) {
      setHasUnreadMessages(true)
    }
  }, [notificationStore.hasUnreadMessages])
  
  return (
    <div className={className}>
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              onClick={handleButtonClick}
              className={` ${open ? "" : "text-opacity-90"} group p-3 hover:bg-gray-100 dark:hover:bg-neutral-800 rounded-full inline-flex items-center text-base font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative`}
            >
              {hasUnreadMessages && (
                <span className="w-2 h-2 bg-red-500 absolute top-2 right-2 rounded-full"></span>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-xs sm:max-w-sm px-4 mt-3 -right-0 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-7">
                    <h3 className="text-xl font-semibold">Notifications</h3>

                    { !isLoading && notificationStore.notifications.map((item, index) => {
                      const notificationContent = buildNotificationMessage(item);
                      
                      return (
                      <Link
                        key={index}
                        to={notificationContent?.redirectUrl || '#'}
                        className="flex p-2 pr-12 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 relative"
                      >
                        <Avatar sizeClass="w-8 h-8 sm:w-12 sm:h-12" imgUrl={notificationContent.imageUrl} />
                        <div className="ml-3 sm:ml-4 space-y-1">
                          <p className="text-sm font-medium text-gray-900 dark:text-gray-200">
                            {item?.data?.senderName}
                          </p>
                          <p className="text-xs text-gray-400 dark:text-gray-400">
                            {notificationContent.time}
                          </p>
                          <p className="text-xs sm:text-sm text-gray-500 dark:text-gray-400">
                            <span dangerouslySetInnerHTML={{ __html: notificationContent.text }} />
                          </p>
                        </div>
                        <button
                          onClick={(e) => handleRemoveNotification(item.id, e)}
                          className="absolute right-2 top-2 p-1 hover:bg-gray-200 dark:hover:bg-gray-600 rounded-full"
                        >
                          <XMarkIcon className="h-4 w-4 text-gray-500" />
                        </button>
                        <span className="absolute right-1 top-1/2 transform -translate-y-1/2 w-2 h-2 rounded-full bg-blue-500"></span>
                      </Link>
                      )
                    })}
                    {isLoading && <Spinner size="lg" />}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
});

export default NotifyDropdown;
