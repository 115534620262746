import { fetchCommunityData } from "api/communities";
import axios from "axios";
import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import CardAuthorBox2 from "components/CardAuthorBox2/CardAuthorBox2";
import CommunityUserBox from "components/CommunitiesSlider/CommunityUserBox";
import FooterNav from "components/FooterNav";
import Heading from "components/Heading/Heading";
import { DEMO_AUTHORS } from "data/authors";
import { AuthorType } from "data/types";
import { CommunityData, CommunityUser } from "interface/communities";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Loader } from "shared/Loader/Loader";

export interface PublicCommunityPageProps {
    className?: string;
    authors?: AuthorType[];
    boxCard?: "box1" | "box2";
    gridClassName?: string;
}

const DEMO_DATA = DEMO_AUTHORS.filter((_, i) => i < 10);

const PublicCommunityPage: FC<PublicCommunityPageProps> = ({
    className = "",
    authors = DEMO_DATA,
    boxCard = "box2",
    gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
}) => {
    const { community_id } = useParams<{ community_id: string }>(); // Get the community_id from the URL
    const [communityData, setCommunityData] = useState<CommunityData>(); // State to store community members
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        // Function to fetch community members
        const fetchCommunityMembers = async () => {
            try {
                if (!community_id) return;
                setLoading(true);
                const response = await fetchCommunityData(community_id);
                setCommunityData(response.data); // Assuming the backend returns community data with members
            } catch (error) {
                console.error("Failed to fetch community members", error);
            } finally {
                setLoading(false);
            }
        };

        if (community_id) {
            fetchCommunityMembers(); // Call the function to fetch data
        }
    }, [community_id]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="container mt-4">
            <div
                className={`nc-SectionGridAuthorBox relative ${className}`}
                data-nc-id="SectionGridAuthorBox"
            >
                <Heading desc="Connect and network with members of your community. Explore profiles, share insights, and grow together." isCenter>
                    {communityData?.name}
                </Heading>
                {communityData?.communityUsers && communityData?.communityUsers.length > 0 ? (
                    <>
                        <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
                            {communityData.communityUsers.map((member: CommunityUser, index) =>
                                boxCard === "box2" ? (
                                    <CommunityUserBox key={member.id} communityUser={member} />
                                ) : null
                            )}
                        </div>
                        <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
                            <ButtonSecondary>Show me more </ButtonSecondary>
                        </div>
                    </>
                ) : (
                    <div className="mt-10 text-center text-gray-500">
                        <p>No community members found.</p>
                        <div className="flex justify-center mt-6">
                            <div className="w-24 h-24 bg-gray-200 rounded-full animate-pulse mr-4"></div>
                            <div className="w-24 h-24 bg-gray-200 rounded-full animate-pulse mr-4"></div>
                            <div className="w-24 h-24 bg-gray-200 rounded-full animate-pulse"></div>
                        </div>
                        <p className="mt-4">Invite members to join the community.</p>
                    </div>
                )}
            </div>
            <FooterNav/>
        </div>
    );
};

export default PublicCommunityPage;
