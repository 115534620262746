import { CommunityData } from "interface/communities";
import Heading from "components/Heading/Heading";
import CommunitiesSlider from "components/CommunitiesSlider/CommunitiesSlider";

interface CommunitiesSectionProps {
  communities: CommunityData[];
}

export const CommunitiesSection = ({ communities }: CommunitiesSectionProps) => {
  return (
    <div className="flex flex-col gap-y-10">
      <Heading
        className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50"
        desc={"Explore Spaces Where Ideas and Opportunities Thrive"}
      >
        Communities
      </Heading>
      <CommunitiesSlider communities={communities} uniqueClassName="communities_slider" />
    </div>
  );
}; 