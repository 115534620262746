import { fetchCommunityData } from 'api/communities';
import { addDoc, collection, limit, onSnapshot, orderBy, query, serverTimestamp, startAfter } from 'firebase/firestore';
import { CommunityData } from 'interface/communities';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Avatar from 'shared/Avatar/Avatar';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { authStore } from 'stores/AuthStore';
import { firestore } from '../../firebase';


const CommunityGroupChat = () => {
    const location = useLocation();
    const [messages, setMessages] = useState<any[]>([]);
    const [newMessage, setNewMessage] = useState('');
    const [lastVisible, setLastVisible] = useState<any>();
    const [hasMore, setHasMore] = useState(true);
    const [community, setCommunity] = useState<CommunityData | null>(null);
    const messagesEndRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const currentUser = authStore.currentUser

    const community_id = queryParams.get('community_id');

    useEffect(() => {
        if (community_id) {
            fetchCommunityData(community_id).then((response) => {
                const { data } = response;

                if (data.communityUsers?.length === 0) {
                    toast.info('Join the community to chat with other members', {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    return
                }

                const communityUser = data.communityUsers?.find((communityUser) => communityUser.user?.id === currentUser?.id)
                if (communityUser) {
                    setCommunity(data);
                    loadInitialMessages();
                } else {
                    toast.error('You are not part of this community', {
                        position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                            icon: '😕'
                        });
                    navigate('/');
                }
            });
        }
    }, [community_id]);


    const loadInitialMessages = async () => {
        const q = query(
            collection(firestore, 'communities', community_id || '', 'messages'), // Access the 'messages' subcollection within the community document
            orderBy('timestamp', 'desc'),
            limit(20)
        );

        const unsubscribe = onSnapshot(q, (snapshot) => {
            if (snapshot.empty) {
                // If no messages exist, create an initial message
                createInitialMessage();
            } else {
                const messagesData = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setMessages(messagesData.reverse());
                setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
                setHasMore(snapshot.docs.length === 20);
            }
        });

        return () => unsubscribe();
    };


    const createInitialMessage = async () => {
        const messagesCollection = collection(firestore, 'communities', community_id || '', 'messages');
        await addDoc(messagesCollection, {
            text: 'Welcome to the chat!',
            uid: 'system',
            displayName: 'System',
            timestamp: serverTimestamp(),
        });
    };

    const loadMoreMessages = async () => {
        console.log('Loading more messages...');

        if (!lastVisible) return;
        const q = query(
            collection(firestore, 'communities', community_id || '', 'messages'),
            orderBy('timestamp', 'desc'),
            startAfter(lastVisible),
            limit(20)
        );
        const unsubscribe = onSnapshot(q, (snapshot) => {
            const newMessages = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setMessages((prevMessages) => [...newMessages.reverse(), ...prevMessages]);
            setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
            setHasMore(snapshot.docs.length === 20);
        });

        return () => unsubscribe();
    };

    const handleSendMessage = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        if (!currentUser?.id || !currentUser?.profile) {
            console.error('User data is missing.');
            return;
        }

        const { id, profile } = currentUser;
        await addDoc(collection(firestore, 'communities', community_id || '', 'messages'), {
            text: newMessage,
            id,
            displayName: profile.fullName,
            timestamp: serverTimestamp(),
            avatar: profile.avatar,
        });

        setNewMessage('');
        scrollToBottom();
    };

    const handleNameClick = (id: string) => {
        navigate(`/profile/${id}`);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className="flex flex-col h-[100dvh] bg-gray-100">
            <div className="flex-1 flex flex-col h-full">
                {/* Chat header */}
                <div className="bg-white border-b shadow-sm p-4 flex items-center space-x-4 sticky top-0 z-10">
                    <button
                        onClick={() => navigate(-1)}
                        className="text-gray-600 hover:text-gray-800"
                    >
                        <i className="las la-arrow-left text-xl"></i>
                    </button>
                    <Avatar 
                        imgUrl={''} 
                        containerClassName="w-10 h-10 rounded-full"
                    />
                    <div>
                        <h1 className="font-semibold text-gray-800">{community?.name}</h1>
                        <p className="text-sm text-gray-500">{community?.communityUsers?.length} members</p>
                    </div>
                </div>

                {/* Messages area */}
                <div 
                    id="scrollableDiv" 
                    className="flex-1 overflow-y-auto bg-gradient-to-b from-gray-50 to-gray-100 p-4 pb-24"
                    style={{
                        background: `linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%)`
                    }}
                >
                    <InfiniteScroll
                        dataLength={messages.length}
                        next={loadMoreMessages}
                        hasMore={hasMore}
                        loader={<div className="text-center py-4">Loading...</div>}
                        scrollableTarget="scrollableDiv"
                        inverse={true}
                        className="space-y-2"
                    >
                        {messages.map(({ id, displayName, text, timestamp, uid, avatar }, index) => {
                            const isCurrentUser = uid === currentUser?.id;
                            return (
                                <div 
                                    key={index} 
                                    className={`${isCurrentUser ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div 
                                        className={`max-w-[70%] break-words rounded-lg px-4 py-2 shadow-sm ${isCurrentUser ? 'bg-[#dcf8c6] ml-12' : 'bg-white mr-12'}`}
                                    >
                                        {!isCurrentUser && (
                                            <div className="flex items-center space-x-2 mb-1">
                                                <Avatar 
                                                    imgUrl={avatar} 
                                                    containerClassName="w-6 h-6 rounded-full"
                                                />
                                                <button
                                                    onClick={() => handleNameClick(uid)}
                                                    className="text-blue-600 text-sm font-medium hover:underline"
                                                >
                                                    {displayName}
                                                </button>
                                            </div>
                                        )}
                                        <p className="text-gray-800">{text}</p>
                                        <div className="flex justify-end items-center space-x-1 mt-1">
                                            <span className="text-xs text-gray-500">
                                                {moment(timestamp?.seconds * 1000).format('LT')}
                                            </span>
                                            {isCurrentUser && (
                                                <i className="las la-check-double text-xs text-blue-500"></i>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </InfiniteScroll>
                    <div ref={messagesEndRef} />
                </div>

                {/* Message input */}
                <form
                    onSubmit={handleSendMessage}
                    className="bg-white border-t p-4 flex items-center space-x-4 fixed bottom-0 left-0 right-0 z-10"
                >
                    <button
                        type="button"
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <i className="las la-paperclip text-xl"></i>
                    </button>
                    <input
                        type="text"
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        className="flex-1 bg-gray-100 rounded-full px-6 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Type a message"
                    />
                    <ButtonPrimary
                        type="submit"
                        className="w-12 h-12 rounded-full flex items-center justify-center bg-blue-500 hover:bg-blue-600"
                    >
                        <i className="las la-paper-plane text-xl"></i>
                    </ButtonPrimary>
                </form>
            </div>
        </div>
    );
}

export default observer(CommunityGroupChat);
