import { GlobeAltIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import { AuthorType } from "data/types";
import { ActivityAttendance, ActivityResponse } from "interface/activity";
import { User } from "interface/user.interface";
import moment from "moment-timezone";
import { FC } from "react";
import { Link } from "react-router-dom";
import AttendeesList from "shared/AttendeesList/AttendeesList";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardActivityCreatorProps {
  className?: string;
  activity: ActivityResponse;
  attendees?: ActivityAttendance[];
}

function transformUserToAuthor(user: User): AuthorType {
  const [firstName, ...lastNameParts] = user.profile.fullName.split(' ');
  const lastName = lastNameParts.join(' ');

  return {
    id: user.id,
    firstName: firstName,
    lastName: lastName || '',
    displayName: user.profile.fullName,
    avatar: user.profile.avatar || '', // Fallback if avatar is undefined
    bgImage: user.profile.bgImg || undefined,
    email: user.email || undefined,
    count: 0, // This could represent a specific count, set dynamically if needed
    desc: user.profile.bio,
    jobName: user.profile.role,
    href: `/users/${user.id}`, // Assuming a URL format for the user's profile
    starRating: undefined, // Assuming starRating is optional and can be set elsewhere
    country: user.profile.country || undefined
  };
}

const CardActivityCreator: FC<CardActivityCreatorProps> = ({
  className = "",
  activity,
  attendees = []
}) => {
  const activityUrl = `/activity/${activity.id}/page`;
  const author = transformUserToAuthor(activity.author);

  const { displayName, avatar } = author;
  const formattedStartDate = moment(activity.startDate).format("ll");
  const timeObject = moment(activity.startTime, "HH:mm:ss");
  const formattedStartTime = timeObject.format("hh:mm A");
  const formattedEndDate = activity.endDate
    ? moment(activity.endDate).format("lll")
    : "";
  const timeObjectEnd = activity.endTime ? moment(activity.endTime, "HH:mm:ss") : null;
  const formattedEndTime = timeObjectEnd ? timeObjectEnd.format("hh:mm A") : "";


  const truncatedDescription =
    activity.description && activity.description.length > 20
      ? `${activity.description.slice(0, 20)}...`
      : activity.description;

  return (
    <Link
      to={activityUrl}
      className={`nc-CardActivityCreator flex flex-col overflow-hidden bg-white dark:bg-neutral-900 rounded-3xl hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="CardActivityCreator"
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            containerClassName="flex aspect-w-7 aspect-h-3 md:aspect-h-4 w-full h-0"
            src={activity.image}
          />
        </div>
        <div className="absolute top-3 left-3 flex space-x-2">
          {/* Participants Badge */}
          <div className="flex items-center space-x-1 py-1 px-3 bg-neutral-100 dark:bg-neutral-800 rounded-full text-xs font-medium">
            <UserGroupIcon className="w-4 h-4 text-yellow-600" />
            <span>{convertNumbThousand(activity.attendees.length)}</span>
          </div>

          {/* Activity Type Badge */}
          <div className="flex items-center space-x-1 py-1 px-3 bg-neutral-100 dark:bg-neutral-800 rounded-full text-xs font-medium">
            <GlobeAltIcon className="w-4 h-4 text-yellow-600" />
            <span>{activity.activityType}</span>
          </div>
        </div>
      </div>
      <div className="pt-[1px] px-6 text-center flex flex-col items-center relative -translate-y-7">
        <svg
          className="h-12 text-white dark:text-neutral-900 dark:group-hover:text-neutral-800"
          viewBox="0 0 135 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M101.911 19.8581C99.4421 17.4194 97.15 14.8065 94.6816 12.1935C94.3289 11.671 93.8 11.3226 93.271 10.8C92.9184 10.4516 92.7421 10.2774 92.3895 9.92903C85.8658 3.83226 76.8737 0 67.1763 0C57.4789 0 48.4868 3.83226 41.7868 9.92903C41.4342 10.2774 41.2579 10.4516 40.9053 10.8C40.3763 11.3226 40.0237 11.671 39.4947 12.1935C37.0263 14.8065 34.7342 17.4194 32.2658 19.8581C23.45 28.7419 11.6368 30.4839 0 30.8323V54H16.5737H32.2658H101.734H110.374H134.176V30.6581C122.539 30.3097 110.726 28.7419 101.911 19.8581Z"
            fill="currentColor"
          />
        </svg>
        <span className="absolute top-2">
          <Avatar
            containerClassName=""
            sizeClass="w-12 h-12 text-2xl"
            radius="rounded-full"
            imgUrl={avatar}
            userName={displayName}
          />
        </span>

        <div className="mt-6">
          <h2 className={`text-base font-medium`}>
            <span className="line-clamp-1">{activity.title}</span>
          </h2>
          <p className="mt-auto text-xs text-gray-500 word-break">
            {truncatedDescription}
          </p>
          <span className="block mt-1 text-sm text-neutral-500 dark:text-neutral-400">
            {formattedStartDate} {formattedStartTime}
          </span>
          {formattedEndDate ? <span className="block text-sm text-neutral-500 dark:text-neutral-400">
            {formattedEndDate} {formattedEndTime}
          </span> : null}

          <div className="mt-4">
            <AttendeesList attendees={attendees} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default CardActivityCreator;
