export const Languages = [
    { "name": "English", "nativeName": "English", "defaultChecked": true },
    { "name": "Spanish", "nativeName": "Español" },
    { "name": "French", "nativeName": "Français" },
    { "name": "Chinese", "nativeName": "中文" },
    { "name": "Russian", "nativeName": "Русский" },
    { "name": "Arabic", "nativeName": "العربية" },
    { "name": "Hindi", "nativeName": "हिन्दी" },
    { "name": "Bengali", "nativeName": "বাংলা" },
    { "name": "Portuguese", "nativeName": "Português" },
    { "name": "Japanese", "nativeName": "日本語" },
    { "name": "Hebrew", "nativeName": "עברית" },
  ]