import CardActivityCreator from "components/ActivityCreator/CardActivityCreator";
import CardAuthorBox from "components/CardAuthorBox/CardAuthorBox";
import Heading from "components/Heading/Heading";
import NoResultCard from "components/NoResult/GenericNoResultCard";
import { AuthorType } from "data/types";
import { ActivityResponse } from "interface/activity";
import { User } from "interface/user.interface";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface SectionGridActivityBoxProps {
  className?: string;
  activities?: ActivityResponse[];
  boxCard?: "box1" | "box2";
  gridClassName?: string;
  heading?: string;
  desc?: string;
}

const SectionGridActivityBox: FC<SectionGridActivityBoxProps> = ({
  className = "",
  activities = [],
  boxCard = "box1",
  gridClassName = "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 ",
  heading = " ",
  desc = ""
}) => {

  const navigate = useNavigate();

  return (
    <div
      className={`nc-SectionGridAuthorBox relative ${className}`}
      data-nc-id="SectionGridAuthorBox"
    >
      <Heading desc={desc} isCenter>{heading}</Heading>
      <div className={`grid gap-6 md:gap-8 ${gridClassName}`}>
        {activities.length === 0 ? <NoResultCard displayName="✨ Be the first to create an activity!" /> :
          activities.map((activity, index) =>
            boxCard === "box2" ? (
              <CardActivityCreator key={activity.id} activity={activity}/>
            ) : null
          )}
      </div>
      <div className="mt-16 flex flex-col sm:flex-row justify-center space-y-3 sm:space-y-0 sm:space-x-5">
        {activities.length >= 10 && (
          <ButtonSecondary>Show me more</ButtonSecondary>
        )}
        <ButtonPrimary onClick={() => navigate('/activity/create')}>Create an Activity</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridActivityBox;
