import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Connection, getConnections } from 'api/connections';
import Heading from 'components/Heading/Heading';
import NoResults from 'components/NoResults/NoResults';
import FooterNav from 'components/FooterNav';
import Avatar from 'shared/Avatar/Avatar';

const ConnectionsPage: React.FC = () => {
  const [connections, setConnections] = useState<Connection[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchConnections = async () => {
    try {
      const data = await getConnections();
      setConnections(data);
    } catch (error) {
      toast.error('Failed to fetch connections');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConnections();
  }, []);

  const handleProfileClick = (userId: string) => {
    navigate(`/user/${userId}`);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 max-w-4xl">
      <Heading desc="People you're connected with">My Connections</Heading>
      
      {connections.length === 0 ? (
        <NoResults 
          message="You haven't connected with anyone yet" 
          icon="👥"
        />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {connections.map((connection) => (
            <div 
              key={connection.connectionId}
              onClick={() => handleProfileClick(connection.user.id)}
              className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-200 cursor-pointer"
            >
              <div className="flex items-center space-x-4">
                <div className="relative">
                  <Avatar
                    radius="rounded-full"
                    imgUrl={connection.user.profile?.avatar}
                    sizeClass="h-16 w-16 text-xl"
                  />
                </div>
                <div className="flex-1">
                    {connection.user.profile?.fullName && (
                        <h3 className="font-semibold text-lg">{connection.user.profile.fullName}</h3>
                    )}
                    {connection.user.profile?.role && (
                        <p className="text-sm text-gray-700">
                            {connection.user.profile.role}{connection.user.profile?.companyName && ` @ ${connection.user.profile.companyName}`}
                        </p>
                    )}
                    {connection.user.profile?.industry && (
                        <p className="text-sm text-gray-600">
                            {connection.user.profile.industry}
                        </p>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <FooterNav />
    </div>
  );
};

export default observer(ConnectionsPage); 