import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import { useEffect } from "react";
import FooterNav from "components/FooterNav";
import { useHomeData } from "./hooks/useHomeData";
import { ExploreSection } from "./components/ExploreSection";
import HeaderFilter from "./HeaderFilter";
import { notificationStore } from "stores/NotificationsStore";
import { tripStore } from "stores/TripStore";
import { EventsSection } from "./components/EventsSection";
import { TripSection } from "./components/TripSection";
import { CommunitiesSection } from "./components/CommunitiesSection";
import { ActivitiesSection } from "./components/ActivitiesSection";

function PageHome() {
  const {
    activeTab,
    currentTrip,
    data,
    setActiveTabHandler,
    isLoading
  } = useHomeData();

  useEffect(() => {
    tripStore.fetchMyTrips();
    notificationStore.checkForUnreadMessages();
  }, []);

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Home Page</title>
      </Helmet>

      <div className="container relative space-y-5 mb-24 lg:space-y-5 lg:mb-28">
        <div className="relative">
          <HeaderFilter
            tabActive={activeTab}
            tabs={tripStore.myTrips}
            onClickTab={(tab, trip) => setActiveTabHandler(tab, trip)}
          />
        </div>

        <EventsSection currentTripId={currentTrip?.id} />
        
        {activeTab === "Explore" ? (
          <ExploreSection 
            professionalLocals={data.professionalLocals}
            arrivalsNextMonth={tripStore.paginatedArrivalsNextMonth}
            isLoading={isLoading}
          />
        ) : (
          <TripSection
            currentTrip={currentTrip}
            localsAtDestination={tripStore.localsAtDestination}
            matchingTravelers={tripStore.matchingTravelers}
            isLoading={isLoading}
          />
        )}

        <CommunitiesSection communities={data.communities} />
        <ActivitiesSection activities={data.activities} />
      </div>
      
      <FooterNav />
    </div>
  );
}

export default observer(PageHome);
