import React from 'react';

interface BlueLoaderProps {
  className?: string;
  fullScreen?: boolean;
}

const BlueLoader: React.FC<BlueLoaderProps> = ({ className = '', fullScreen = true }) => {
  const containerClasses = fullScreen 
    ? 'flex justify-center items-center min-h-screen'
    : 'flex justify-center items-center';

  return (
    <div className={containerClasses}>
      <div className={`animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500 ${className}`}></div>
    </div>
  );
};

export default BlueLoader; 