import { BulletinResponse } from "interface/communities";
import moment from "moment";
import { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";

export interface BulletinCardMetaProps {
  className?: string;
  meta: Pick<BulletinResponse, "createdAt" | "user">;
  hiddenAvatar?: boolean;
  size?: "large" | "normal";
}

const BulletinCardMeta: FC<BulletinCardMetaProps> = ({
  className = "leading-none",
  meta,
  hiddenAvatar = false,
  size = "normal",
}) => {
  const { createdAt, user } = meta;
  const href = `/user/${user?.id}`;
  return (
    <div
      className={`nc-PostCardMeta inline-flex items-center fledx-wrap text-neutral-800 dark:text-neutral-200 ${
        size === "normal" ? "text-sm" : "text-base"
      } ${className}`}
      data-nc-id="PostCardMeta"
    >
      <Link
        to={href}
        className="flex-shrink-0 relative flex items-center space-x-2"
      >
        {!hiddenAvatar && (
          <Avatar
            radius="rounded-full"
            sizeClass={
              size === "normal" ? "h-7 w-7 text-sm" : "h-10 w-10 text-xl"
            }
            imgUrl={user?.profile?.avatar}
            userName={user?.profile?.fullName}
          />
        )}
        <span className="block text-neutral-6000 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
          {user?.profile?.fullName}
        </span>
      </Link>
      <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal line-clamp-1">
          {moment(createdAt).format('ll')}
        </span>
      </>
    </div>
  );
};

export default BulletinCardMeta;
