import { Dialog, Tab, Transition } from "@headlessui/react";
import { MapIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { AddPlaceBodyRequest, addPlaceToTrip } from "api/trip";
import AddTripPlaceForm from "components/HeroSearchForm2Mobile/(add-trip-place)/AddTripPlaceForm";
import { TripDetails } from "interface/trip.interface";
import React, { Fragment, useState } from "react";
import { AutocompletePrediction } from "react-places-autocomplete";
import ButtonSubmit from "./ButtonSubmit";
import { toast } from "react-toastify";

interface AddPlaceBtnProps {
    trip: TripDetails; // Replace TripDetails with the actual type of your trip
    setItineraryData: React.Dispatch<React.SetStateAction<TripDetails | null>>
}

const AddPlaceBtn: React.FC<AddPlaceBtnProps> = ({ trip, setItineraryData }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [locationObject, setLocation] = useState<AutocompletePrediction | null>(null);
    const [date, setDate] = useState<Date | null>(new Date());

    const tabsConfiguration = {
        buttonConfiguration: {
            text: 'Manage Trip',
            subText: 'Add a place • Explore • Add guests',
            icon: MapIcon,
        },
        tabs: [
            {
                text: 'Places to visit',
                component: AddTripPlaceForm,
            }
        ]
    }

    function closeModal(): void {
        setShowDialog(false);
    }
    function showModal(): void {
        setShowDialog(true);
    }

    const handleSubmit = async () => {
        if (locationObject && date) {
            const newPlace = createPlaceFromGooglePlacesResponse(locationObject, date);
            const { data } = await addPlaceToTrip(trip.id, newPlace)
            // trip.places.push(data);
            setItineraryData(data);
            toast.success("Place added successfully");
            closeModal()
        }
    };

    const createPlaceFromGooglePlacesResponse = (locationPickUp: AutocompletePrediction, date: Date): AddPlaceBodyRequest => {
        const place: AddPlaceBodyRequest = {
            name: locationPickUp.structured_formatting.main_text,
            placeId: locationPickUp.place_id,
            address: locationPickUp.structured_formatting.secondary_text,
            types: locationPickUp.types,
            date: date,
            notes: null,
            files: null,
        };

        return place;
    }
    return (
        <div className="flow-root">
            <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
                <span onClick={showModal} className="py-1.5 px-3 flex items-center rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer border border-neutral-200 dark:border-neutral-700">
                    <i className="las la-plus"></i>
                    <span className="hidden sm:block ml-2.5">Add place</span>
                </span>
                <Transition appear show={showDialog} as={Fragment}>
                    <Dialog
                        as="div"
                        className="HeroSearchFormMobile__Dialog relative z-max"
                        onClose={closeModal}
                    >
                        <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
                            <div className="flex h-full">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out transition-transform"
                                    enterFrom="opacity-0 translate-y-52"
                                    enterTo="opacity-100 translate-y-0"
                                    leave="ease-in transition-transform"
                                    leaveFrom="opacity-100 translate-y-0"
                                    leaveTo="opacity-0 translate-y-52"
                                >
                                    <Dialog.Panel className="relative h-full overflow-hidden flex-1 flex flex-col justify-between ">
                                        {showDialog && (
                                            <Tab.Group manual>
                                                <div className="absolute left-4 top-4">
                                                    <button className="" onClick={closeModal}>
                                                        <XMarkIcon className="w-5 h-5 text-black dark:text-white" />
                                                    </button>
                                                </div>

                                                <Tab.List className="pt-12 flex w-full justify-center font-semibold text-sm sm:text-base text-neutral-500 dark:text-neutral-400 space-x-6 sm:space-x-8">
                                                    {/* {["Flights", "Stay", "Experiences", "Cars"].map( */}
                                                    {tabsConfiguration.tabs.map(
                                                        (item, index) => (
                                                            <Tab key={index} as={Fragment}>
                                                                {({ selected }) => (
                                                                    <div className="relative focus:outline-none focus-visible:ring-0 outline-none select-none">
                                                                        <div
                                                                            className={`${selected
                                                                                ? "text-black dark:text-white"
                                                                                : ""
                                                                                }  `}
                                                                        >
                                                                            {item.text}
                                                                        </div>
                                                                        {selected && (
                                                                            <span className="absolute inset-x-0 top-full border-b-2 border-black dark:border-white"></span>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </Tab>
                                                        )
                                                    )}
                                                </Tab.List>
                                                <div className="flex-1 pt-3 px-1.5 sm:px-4 flex overflow-hidden">
                                                    <Tab.Panels className="flex-1 overflow-y-auto hiddenScrollbar py-4">
                                                        {tabsConfiguration.tabs.map((tab, index) => (
                                                            <Tab.Panel key={index} className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                                                                <tab.component trip={trip} setLocation={setLocation} setDate={setDate} />
                                                            </Tab.Panel>
                                                        ))}
                                                        {/* <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <FlightSearchForm onExternalTrigger={trigger} />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <StaySearchForm />
                            </div>
                          </Tab.Panel>
                          <Tab.Panel>
                            <div className="transition-opacity animate-[myblur_0.4s_ease-in-out]">
                              <CarsSearchForm />
                            </div>
                          </Tab.Panel> */}
                                                    </Tab.Panels>
                                                </div>
                                                <div className="px-4 py-3 bg-white dark:bg-neutral-900 border-t border-neutral-200 dark:border-neutral-700 flex justify-between">
                                                    <button
                                                        type="button"
                                                        className="underline font-semibold flex-shrink-0"
                                                        onClick={() => {
                                                            setShowDialog(false);
                                                        }}
                                                    >
                                                        Clear all
                                                    </button>
                                                    <ButtonSubmit
                                                        text="Send"
                                                        onClick={handleSubmit}
                                                        SvgIcon={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 48 48"><g fill="none" stroke="currentColor" strokeLinejoin="round" strokeWidth={4}><path d="M42 6L4 20.138l20 3.87L29.005 44z"></path><path strokeLinecap="round" d="m24.008 24.008l5.657-5.656"></path></g></svg>}
                                                    />
                                                </div>
                                            </Tab.Group>
                                        )}
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
                {/* <span className="py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={1.5}
                            d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                        />
                    </svg>
                    <span className="hidden sm:block ml-2.5">Save</span>
                </span> */}
            </div>
        </div >
    );
};

export default AddPlaceBtn;
