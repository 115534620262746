import React, { FC } from "react";
import { ActivityAttendance } from "interface/activity";

interface AttendeesListProps {
  attendees: ActivityAttendance[];
  maxDisplay?: number;
  avatarSize?: string;
  className?: string;
}

const AttendeesList: FC<AttendeesListProps> = ({
  attendees,
  maxDisplay = 4,
  avatarSize = "w-8 h-8",
  className = "",
}) => {
  return (
    <div className={`flex ${className}`}>
      {attendees.slice(0, maxDisplay).map((attendee, index) => (
        <a
          key={attendee.id}
          href="javascript:;"
          className={`relative z-20 inline-flex items-center justify-center ${avatarSize} text-white transition-all duration-200 border-2 border-white border-solid ease-soft-in-out text-xs rounded-full hover:z-30 ${
            index > 0 ? '-ml-4' : ''
          }`}
        >
          <img
            className="w-full rounded-full"
            alt={`${attendee.user.profile.fullName || 'Attendee'}`}
            src={attendee.user.profile.avatar || ''}
          />
        </a>
      ))}
      {attendees.length > maxDisplay && (
        <a
          href="javascript:;"
          className={`relative z-20 inline-flex items-center justify-center ${avatarSize} -ml-4 text-white transition-all duration-200 border-2 border-white border-solid ease-soft-in-out text-xs rounded-full hover:z-30 bg-gray-200`}
        >
          <span className="text-xs text-gray-600">+{attendees.length - maxDisplay}</span>
        </a>
      )}
    </div>
  );
};

export default AttendeesList; 