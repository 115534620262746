import { ConnectionResponse } from 'api/connections';
import { User } from 'interface/user.interface';
import React from 'react';
import { Link } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

interface ConnectionButtonsProps {
    connection: ConnectionResponse | null;
    user: User | null;
    onHandshake: () => void;
    onCancel: () => void;
    onConfirm: () => void;
    onDecline: () => void;
    isLoading: boolean;
}

const ConnectionButtons: React.FC<ConnectionButtonsProps> = ({
    connection,
    user,
    onHandshake,
    onCancel,
    onConfirm,
    onDecline,
    isLoading
}) => {
    
    if (isLoading) {
        return (
            <ButtonPrimary
                disabled={true}
                loading={true}
                className="w-full sm:w-auto"
            >
                Loading
            </ButtonPrimary>
        );
    }
    
    if (!connection || !user) {
        return (
            <ButtonPrimary
                onClick={onHandshake}
                className="w-full sm:w-auto"
            >
                Handshake
                <i className="ml-2 las la-handshake text-lg"></i>
            </ButtonPrimary>
        );
    }

    return (
        <div className="flex flex-col sm:flex-row gap-2">
            {!connection && (
                <ButtonPrimary
                    onClick={onHandshake}
                    className="w-full sm:w-auto"
                >
                    Handshake
                </ButtonPrimary>
            )}
            {connection && connection.status === 'pending' && connection.sender.id === user.id && (
                <ButtonPrimary
                    onClick={onCancel}
                    className="w-full sm:w-auto bg-gray-500 hover:bg-gray-600"
                >
                    Cancel Request
                </ButtonPrimary>
            )}
            {connection && connection.status === 'pending' && connection.receiver.id === user.id && (
                <div className="flex flex-row sm:flex-row gap-2">
                    <ButtonPrimary
                        onClick={onConfirm}
                        className="w-full sm:w-auto"
                    >
                        Confirm
                    </ButtonPrimary>
                    <ButtonPrimary
                        onClick={onDecline}
                        className="w-full sm:w-auto bg-red-500 hover:bg-red-600"
                    >
                        Decline
                    </ButtonPrimary>
                </div>
            )}
            {connection && connection.status === 'approved' && (
                <ButtonPrimary
                    href={`/chat/${connection.sender.id === user.id ? connection?.receiver?.id : connection.sender.id}`}
                    className="w-full sm:w-auto"
                >
                    Go To Chat
                </ButtonPrimary>
            )}
            {connection && connection.status === 'declined' && (
                <ButtonPrimary
                    disabled={true}
                    className="w-full sm:w-auto bg-gray-500"
                >
                    {connection.sender.id === user.id ? 'Blocked' : 'Declined'}
                </ButtonPrimary>
            )}
        </div>
    );
};

export default ConnectionButtons;
