import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

const DatePickerCustomHeaderSingleMonth = ({
  monthDate,
  decreaseMonth,
  increaseMonth,
}: ReactDatePickerCustomHeaderProps) => {
  return (
    <div className="flex justify-between items-center p-2">
      <button
        aria-label="Previous Month"
        className="react-datepicker__navigation react-datepicker__navigation--previous p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
        onClick={decreaseMonth}
        type="button"
      >
        <ChevronLeftIcon className="w-5 h-5" />
      </button>
      <span className="react-datepicker__current-month font-medium">
        {monthDate.toLocaleString("en-US", {
          month: "long",
          year: "numeric",
        })}
      </span>
      <button
        aria-label="Next Month"
        className="react-datepicker__navigation react-datepicker__navigation--next p-2 rounded-full hover:bg-gray-100 dark:hover:bg-gray-700"
        onClick={increaseMonth}
        type="button"
      >
        <ChevronRightIcon className="w-5 h-5" />
      </button>
    </div>
  );
};

export default DatePickerCustomHeaderSingleMonth;
