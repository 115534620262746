import { FC } from "react";

const SkeletonBulletinCard: FC = () => {
  return (
    <div className="p-4 xl:px-5 xl:py-6 animate-pulse">
      <div className="h-4 bg-gray-300 dark:bg-gray-600 rounded w-1/3 mb-2"></div>
      <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-2/3 mb-1"></div>
      <div className="h-3 bg-gray-300 dark:bg-gray-600 rounded w-full"></div>
    </div>
  );
};

export default SkeletonBulletinCard;
