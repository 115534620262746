import { useState } from 'react';
import FooterNav from 'components/FooterNav';
import CommonLayout from 'containers/AccountPage/CommonLayout';
import { observer } from 'mobx-react';
import { authStore } from 'stores/AuthStore';
import { goals } from 'stores/OnboardingStore';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

function PageMyGoals({ className = "" }) {
    const { currentUser } = authStore;

    // Local state to manage goals
    const [selectedGoals, setSelectedGoals] = useState<string[]>(currentUser?.profile?.goals ?? []);
    const [loading, setLoading] = useState<boolean>(false);

    const toggleGoal = (goalName: string) => {
        setSelectedGoals((prevGoals) =>
            prevGoals.includes(goalName)
                ? prevGoals.filter((id) => id !== goalName)
                : [...prevGoals, goalName]
        );
    };

    const saveGoals = () => {
        if (currentUser?.id) {
            setLoading(true);
            authStore.updateProfile({ id: currentUser.id, goals: selectedGoals });
            setLoading(false);
        }
    };

    return (
        <CommonLayout>
            <div className="space-y-6 sm:space-y-8">
                <h2 className="text-3xl font-semibold">Set up your goals</h2>
                <p className="text-center text-gray-500 mb-6">
                    Choose your goals to help us understand your priorities.
                    This information allows our AI to suggest the most relevant connections and opportunities for you.
                </p>

                {/* FORM */}
                <div className="space-y-8">
                    {/* ITEM */}
                    <div
                        className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}
                        data-nc-id="WidgetTags"
                    >
                        <div className="max-w-md mx-auto p-4 rounded-md shadow-md">
                            <div className="grid grid-cols-2 gap-4">
                                {goals.map((goal) => (
                                    <button
                                        key={goal.name}
                                        className={`flex flex-col items-center justify-center w-30 h-35 p-4 border rounded-xl transition-colors duration-200 ${selectedGoals.includes(goal.name)
                                            ? 'dark:bg-neutral-800 border-blue-700'
                                            : 'border-gray-300'
                                            }`}
                                        onClick={() => toggleGoal(goal.name)}
                                    >
                                        <span className="text-2xl mb-2">{goal.icon}</span>
                                        <span className="text-center text-sm">{goal.name}</span>
                                    </button>
                                ))}
                            </div>
                            {/* Save Button */}
                            <div className="mt-4 w-full text-center">
                                <ButtonPrimary
                                    loading={loading}
                                    className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700"
                                    onClick={saveGoals}
                                >
                                    Update Goals
                                </ButtonPrimary>
                            </div>
                        </div>
                    </div>
                </div>


                <FooterNav />
            </div>
        </CommonLayout>
    );
}

export default observer(PageMyGoals);
