import { Combobox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { FC, useState, useEffect } from 'react';

export interface AutocompleteProps {
    defaultValue?: string;
    options: string[];
    placeholder?: string;
    inputClass?: string;
    onChange: (value: string) => void;
}

const Autocomplete: FC<AutocompleteProps> = ({
    defaultValue = '',
    options,
    placeholder,
    inputClass,
    onChange,
}) => {
    const [selected, setSelected] = useState(defaultValue);
    const [query, setQuery] = useState('');

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) =>
                option.toLowerCase().includes(query.toLowerCase())
            );

    useEffect(() => {
        setSelected(defaultValue);
    }, [defaultValue]);

    const handleInputChange = (value: string) => {
        setQuery(value);
        setSelected(value); // Update selected value as user types
        onChange(value); // Trigger onChange with the current input value
    };

    return (
        <Combobox
            value={selected}
            onChange={(value) => {
                setSelected(value);
                setQuery(value);
                onChange(value);
            }}
        >
            <div className="relative">
                <div className="relative w-full">
                    <Combobox.Input
                        className={`w-full rounded-lg border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 ${inputClass}`}
                        placeholder={placeholder}
                        onChange={(event) => handleInputChange(event.target.value)}
                        displayValue={(option: string) => option}
                        autoComplete="off"
                    />
                    <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronUpDownIcon
                            className="h-5 w-5 text-neutral-400"
                            aria-hidden="true"
                        />
                    </Combobox.Button>
                </div>
                {query && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-neutral-900 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredOptions.length === 0 ? (
                            <div className="relative cursor-default select-none px-4 py-2 text-neutral-700 dark:text-neutral-300">
                                Press Enter to add "{query}"
                            </div>
                        ) : (
                            filteredOptions.map((option) => (
                                <Combobox.Option
                                    key={option}
                                    className={({ active }) =>
                                        `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                                            active
                                                ? 'bg-primary-100 dark:bg-primary-700 text-primary-900 dark:text-white'
                                                : 'text-neutral-900 dark:text-neutral-100'
                                        }`
                                    }
                                    value={option}
                                >
                                    {({ selected, active }) => (
                                        <>
                                            <span
                                                className={`block truncate ${
                                                    selected ? 'font-medium' : 'font-normal'
                                                }`}
                                            >
                                                {option}
                                            </span>
                                            {selected ? (
                                                <span
                                                    className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                        active ? 'text-primary-600' : 'text-primary-500'
                                                    }`}
                                                >
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                            ) : null}
                                        </>
                                    )}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};

export default Autocomplete;
