import { FC } from "react";
import { SparklesIcon, StarIcon } from "@heroicons/react/24/solid";
import { CloudIcon, BoltSlashIcon } from "@heroicons/react/24/outline";

interface BadgeScoreProps {
  className?: string;
  matchScore?: number;
  name?: string;
  color?: string;
}

const BadgeScore: FC<BadgeScoreProps> = ({
  className = "",
  matchScore = 0,
  name,
  color = 'gray',
}) => {  
  const getScoreColor = (score: number) => {
    if (score >= 80) return "bg-gradient-to-r from-purple-600 to-blue-600";
    if (score >= 60) return "bg-gradient-to-r from-green-500 to-emerald-500";
    if (score >= 30) return "bg-gradient-to-r from-gray-400 to-blue-400";
    return "bg-gradient-to-r from-slate-500 to-gray-600"; // Very low match
  };

  const getScoreIcon = (score: number) => {
    if (score >= 80) return <SparklesIcon className="w-3.5 h-3.5" />;
    if (score >= 60) return <StarIcon className="w-3.5 h-3.5" />;
    if (score >= 30) return <CloudIcon className="w-3.5 h-3.5" />;
    return <BoltSlashIcon className="w-3.5 h-3.5" />; // Icon for very low match
  };

  return (
    <div
      className={`
        flex items-center gap-1
        px-2.5 py-1.5
        rounded-full
        text-white font-medium
        shadow-lg
        ${getScoreColor(matchScore)}
        transition-transform duration-300
        hover:scale-105
        ${className}
      `}
    >
      {getScoreIcon(matchScore)}
      <span className="text-sm">{matchScore}%</span>
    </div>
  );
};

export default BadgeScore;
