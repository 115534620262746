import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import FooterNav from "components/FooterNav";
import StartRating from "components/StartRating/StartRating";
import { amenitiesList } from "data/amenities";
import { CommunityData, MemberRole } from "interface/communities";
import { observer } from "mobx-react-lite";
import { FC, Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ShowMoreText from "react-show-more-text";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import metadataStore from "stores/MetadataStore";
import { PageType, navigationStore } from 'stores/NavigationStore';
import ChatAndShareBtns from "./ChatAndShareBtns";
import CommunityMembersBox from "./CommunityMembersGridBox";
import DigitalBulletinBoard from "./Widgets/DigitalBulletinBoard/DigitalBulletinBoard";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";

interface CommunityDetailPageContainerProps {
  communityData: CommunityData | null;
}

interface ButtonPayload {
  name: string; // The name or label to be displayed inside the button.
  icon: string; // The Line Awesome icon class (e.g., "las la-play").
  onClick: () => void; // The onClick handler for the button.
  description?: string; // Optional description for the button.
}

export const CommunityDetailPageContainer: FC<CommunityDetailPageContainerProps> = observer(({ communityData }) => {
  useEffect(() => {
    navigationStore.setCurrentPage(PageType.COMMUNITY);
  }, []);

  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  // const { community_id } = useParams<{ community_id: string }>(); // Get community_id from URL
  // const [communityData, setCommunityData] = useState<CommunityData | null>(null);

  const thisPathname = useLocation().pathname;
  const navigate = useNavigate();

  const isAdmin = metadataStore.communities?.find(community => community.community.id === communityData?.id && community.role === MemberRole.ADMIN);

  const createRedirectHandler = (path: string) => () => navigate(`${path}?community_id=${communityData?.id}`)

  const redirectToCommunityChat = createRedirectHandler('/community/chat');
  const redirectToCommunityMembersPage = createRedirectHandler(`/community/members/${communityData?.id}/page`);
  const redirectToCommunitySearchPage = createRedirectHandler('/ai-community-search');
  const redirectToCommunityActivitiesSearch = createRedirectHandler(`/community/activities/search?community_id=${communityData?.id}`);

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    navigate(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderAmenities = () => {
    if (!communityData?.amenities?.length || communityData?.amenities?.length > 10) {
      return null;
    }

    return (
      <ButtonSecondary onClick={openModalAmenities}>
        View more {communityData?.amenities?.length} amenities
      </ButtonSecondary>
    )
  };

  const SocialNetworks = () => {
    const socialLinks = [
      {
        name: "Instagram",
        url: communityData?.instagram,
        iconClass: "lab la-instagram",
      },
      {
        name: "Twitter",
        url: communityData?.twitter,
        iconClass: "lab la-twitter",
      },
      {
        name: "Facebook",
        url: communityData?.facebook,
        iconClass: "lab la-facebook",
      },
      {
        name: "LinkedIn",
        url: communityData?.linkedin,
        iconClass: "lab la-linkedin",
      },
    ];

    return (
      <div className="flex items-center space-x-4">
        {socialLinks.map(
          (link) =>
            link.url && (
              <a
                key={link.name}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-neutral-700 dark:text-neutral-300 hover:text-blue-600"
              >
                <i className={`${link.iconClass} text-4xl`}></i>
              </a>
            )
        )}
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name={communityData?.communityType} />
          <ChatAndShareBtns community_id={communityData?.id || ""} />
        </div>

        {/* 4 */}
        <div className="flex items-center">
          <Avatar containerClassName="object-contain" hasChecked sizeClass="h-10 w-10" imgUrl={communityData?.logo} />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            {/* Hosted by{" "} */}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {/* Kevin Francis */}
            </span>
          </span>
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {communityData?.name}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {communityData?.address}</span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        {SocialNetworks()}
        {/* <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-user text-2xl "></i>
            <span className="">
              {communityData?.capacity} <span className="hidden sm:inline-block">Capacity</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              6 <span className="hidden sm:inline-block">beds</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              3 <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-door-open text-2xl"></i>
            <span className=" ">
              2 <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
        </div> */}
      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Community Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            <ShowMoreText
              /* Default options */
              lines={3}
              more="Show more"
              less="Show less"
              anchorClass="show-more-less-clickable"
              expanded={false}
              truncatedEndingComponent={" ... "}
            >
              {communityData?.description}
            </ShowMoreText>
          </span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {communityData?.amenities?.filter((_, i) => i < 12).map((item, i) => (
            <div key={i} className="flex items-center space-x-3">
              <i className={`text-3xl las ${amenitiesList[item as keyof typeof amenitiesList]?.icon}`}></i>
              <span className=" ">{amenitiesList[item as keyof typeof amenitiesList]?.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          {renderAmenities()}
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {communityData?.amenities?.filter((_, i) => i < 1212).map((item, i) => (
                      <div
                        key={i}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${amenitiesList[item as keyof typeof amenitiesList]?.icon}`}
                        ></i>
                        <span>{amenitiesList[item as keyof typeof amenitiesList]?.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>$219</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            {/* <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>90 nights</span>
            </div> */}
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {communityData?.address}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD8NDxby1gIQOt2sIeazztoBERKIglm2z4&q=${communityData?.latitude},${communityData?.longitude}`}
            ></iframe>
          </div>
        </div>
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  // const renderSidebar = () => {
  //   return (
  //     <div className="listingSectionSidebar__wrap shadow-xl">
  //       {/* PRICE */}
  //       <div className="flex justify-between">
  //         <span className="text-3xl font-semibold">
  //           $119
  //           <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
  //             /night
  //           </span>
  //         </span>
  //         <StartRating />
  //       </div>

  //       {/* FORM */}
  //       <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
  //         <StayDatesRangeInput className="flex-1 z-[11]" />
  //         <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <GuestsInput className="flex-1" />
  //       </form>

  //       {/* SUM */}
  //       <div className="flex flex-col space-y-4">
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>$119 x 3 night</span>
  //           <span>$357</span>
  //         </div>
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>Service charge</span>
  //           <span>$0</span>
  //         </div>
  //         <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <div className="flex justify-between font-semibold">
  //           <span>Total</span>
  //           <span>$199</span>
  //         </div>
  //       </div>

  //       {/* SUBMIT */}
  //       <ButtonPrimary href={communityData?.website}>Book a room</ButtonPrimary>
  //     </div>
  //   );
  // };

  const renderSidebar = () => {
    return (
      <>
        <div className="mb-4"><DigitalBulletinBoard communityId={communityData?.id || ""} /></div>
        <div className="listingSectionSidebar__wrap shadow-xl p-6 space-y-6">
          {/* Header and Rating */}
          <div className="flex justify-between items-center">
            <h3 className="text-2xl font-semibold">Book Now</h3>
            {/* <StartRating /> */}
          </div>

          {/* Pricing Options */}
          {/* <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-lg font-medium">Day Pass</span>
            <span className="text-xl font-semibold">$25</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-lg font-medium">Dedicated Desk</span>
            <span className="text-xl font-semibold">$100</span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-lg font-medium">Private Office</span>
            <span className="text-xl font-semibold">$150</span>
          </div>
        </div> */}

          {/* Book Button */}
          <ButtonPrimary href={communityData?.website} className="w-full py-3 text-lg font-semibold">
            Visit Site to Book Now
          </ButtonPrimary>
        </div>
      </>
    );
  };

  const renderButton = (payload: ButtonPayload, index: number) => {
    return (
      <div
        className="group relative aspect-h-16 aspect-w-16 rounded-2xl cursor-pointer overflow-hidden border-2 border-neutral-200 sm:aspect-h-12 sm:aspect-w-12 sm:rounded-3xl lg:aspect-h-9 will-change-transform transition-transform transform hover:scale-105"
        onClick={payload.onClick}
        title={payload.name}
        key={String(index)}
      >
        <div className="absolute inset-0 flex flex-col items-center justify-start sm:justify-center z-10 p-4">
          <i className={`${payload.icon} text-2xl sm:text-4xl mb-2`}></i> {/* Line Awesome Icon */}
          <span className="text-xs text-center max-w-full">{payload.name}</span> {/* Button Label */}
        </div>
      </div>
    );
  };


  const renderActionButtons = () => {
    const buttons: ButtonPayload[] = [
      {
        name: 'Chat Group',
        icon: 'las la-comments',
        onClick: redirectToCommunityChat,
        description: 'Join community discussions'
      },
      {
        name: 'Members',
        icon: 'las la-users',
        onClick: redirectToCommunityMembersPage,
        description: 'View all community members'
      },
      {
        name: 'Search Members',
        icon: 'las la-search',
        onClick: redirectToCommunitySearchPage,
        description: 'Find people in community'
      },
      {
        name: 'Activities',
        icon: 'las la-calendar-check',
        onClick: redirectToCommunityActivitiesSearch,
        description: 'Explore community events'
      }
    ];

    return (
      <div className="listingSection__wrap">
        {/* Mobile View - Horizontal Scroll */}
        <div className="lg:hidden">
          <div className="flex overflow-x-auto pb-4 gap-3 scrollbar-hide -mx-4 px-4">
            {buttons.map((button, index) => (
              <button
                key={index}
                onClick={button.onClick}
                className="flex-shrink-0 flex flex-col items-center justify-start 
                  bg-white rounded-xl shadow-sm hover:shadow-md transition-all
                  w-24 h-24 p-2 space-y-2 border border-gray-100
                  hover:border-blue-100 active:border-blue-200"
              >
                <i className={`${button.icon} text-2xl text-blue-600`} />
                <span className="text-xs font-medium text-gray-700 text-center">
                  {button.name}
                </span>
              </button>
            ))}
          </div>
        </div>

        {/* Desktop View - Grid */}
        <div className="hidden lg:block">
          <div className="grid grid-cols-4 gap-4">
            {buttons.map((button, index) => (
              <button
                key={index}
                onClick={button.onClick}
                className="group relative flex flex-col items-center justify-start 
                  bg-white rounded-xl shadow-sm hover:shadow-md transition-all
                  p-6 hover:-translate-y-1 border border-gray-100
                  hover:border-blue-100 active:border-blue-200"
              >
                <div className="flex flex-col items-center space-y-3">
                  <div className="w-12 h-12 rounded-full bg-blue-50 flex items-center justify-center
                    group-hover:bg-blue-100 transition-colors">
                    <i className={`${button.icon} text-2xl text-blue-600`} />
                  </div>
                  <div className="text-center">
                    <h3 className="font-medium text-gray-900">{button.name}</h3>
                    <p className="text-xs text-gray-500 mt-1">{button.description}</p>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>

        {/* Create Activity Button - Now checks isAdmin */}
        {isAdmin && (
          <div className="mt-6">
            <ButtonPrimary
              onClick={() => navigate(`/activity/create?community_id=${communityData?.id}`)}
              className="w-full flex items-center justify-center gap-2 border border-transparent"
            >
              <i className="las la-plus-circle text-xl" />
              <span>Create New Activity</span>
            </ButtonPrimary>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage mt-2">
      <ScrollToTop />
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={communityData?.gallery?.[0] || ""}
              alt=""
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {communityData?.gallery?.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item || ""}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderActionButtons()}
          {renderSection1()}
          {renderSection2()}
          <CommunityMembersBox communityMembers={communityData?.communityUsers} />
          <div className="block lg:hidden flex-grow mt-14 lg:mt-0">
            <div className="">{renderSidebar()}</div>
          </div>
          {/* <ActivitiesSectionDateRange /> */}
          {renderSection3()}
          {/* {renderSection4()} */}
          {/* {renderSection5()} */}
          {/* {renderSection6()} */}
          {/* {renderSection7()} */}
          {/* {renderSection8()} */}


        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="">{renderSidebar()}</div>
        </div>
      </main>
      <FooterNav />
    </div>
  );
});

// export default function CommunityDetailPage() {
//   return (
//     <CommunityPagetLayout>
//       <CommunityDetailPageContainer />
//     </CommunityPagetLayout>
//   );
// }
