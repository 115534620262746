import { makeAutoObservable } from "mobx";
import { authStore } from "./AuthStore";

export enum PageType {
  LOGIN = 'login',
  COMMUNITY = 'community',
  MAIN = 'main',
  OTHER = 'other'
}

class NavigationStore {
  previousPage: PageType = PageType.OTHER;
  currentPage: PageType = PageType.OTHER;

  constructor() {
    makeAutoObservable(this);
  }

  setCurrentPage(page: PageType) {
    this.previousPage = this.currentPage;
    this.currentPage = page;
  }

  shouldRedirectToCommunity() {
    return this.previousPage !== PageType.COMMUNITY //|| 
        //    (this.previousPage === PageType.LOGIN && !!authStore.currentUser?.communityUsers?.[0]?.community?.id);
  }

  getRedirectPath() {
    if (this.shouldRedirectToCommunity()) {
      const communityId = authStore.currentUser?.communityUsers?.[0]?.community?.id;
      return communityId ? `/community/${communityId}` : '/';
    }
    return '/';
  }
}

export const navigationStore = new NavigationStore(); 