import googleSvg from "images/Google.svg";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";
import { ClimbingBoxLoader } from "react-spinners";
import { toast } from "react-toastify";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import { authStore } from "stores/AuthStore";
import { UserProfile } from "interface/user.interface";
import { PageType, navigationStore } from 'stores/NavigationStore';

const PageLogin = () => {
  const [email, setEmail] = useState('');
  const [isMagicLinkSent, setIsMagicLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigationStore.setCurrentPage(PageType.LOGIN);
  }, []);

  useEffect(() => {
    if (authStore?.currentUser?.profile) {
      const params = new URLSearchParams(location.search);
      const communityId = params.get('community_id');
      
      if (communityId) {
        navigate(`/community/${communityId}`);
        return;
      }

      navigate(navigationStore.getRedirectPath());
    }
  }, [authStore?.currentUser, location.search]);

  useEffect(() => {
    const queryParams = getQueryParams(location.search);

    // If there are query parameters, save them to localStorage
    if (queryParams.company_name) {
      localStorage.setItem('enterpriseParams', JSON.stringify(queryParams));
    }

    if (queryParams.community_id) {
      const communityData = {
        community_id: queryParams.community_id,
        join_by: queryParams.join_by || null,
      };

      localStorage.setItem('communityData', JSON.stringify(communityData));
    }
  }, [location.search]);

  const getQueryParams = (search: string) => {
    const params = new URLSearchParams(search);
    const queryObject: any = {};
    params.forEach((value, key) => {
      queryObject[key] = value;
    });
    return queryObject;
  };

  const handleGoogleSignIn = async () => {
    authStore.setLoading(true);
    try {
      await authStore.googleSignIn()
    } catch (error) {
      console.log(error);
    } finally {
      authStore.setLoading(false);
    }
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent default form submission behavior
    setIsLoading(true);
    await authStore.sendSignInLinkToEmailHandler(email)
    setIsLoading(false);
    setIsMagicLinkSent(true);
    setEmail('')
    toast.success('Verification email has been sent!');
  };

  if (authStore.loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <ClimbingBoxLoader color="#2563eb" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <Helmet>
        <title>Login | MeetWith</title>
      </Helmet>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* Logo or Brand */}
        <h1 className="text-center text-3xl font-bold tracking-tight text-gray-900 mb-2">
          MeetWith
        </h1>
        <h2 className="text-center text-sm text-gray-600 max-w-sm mx-auto">
          Connect with professionals, discover opportunities, and grow your network
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-sm sm:rounded-lg sm:px-10 border border-gray-100">
          <div className="space-y-6">
            {/* Google Sign In */}
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex justify-center items-center gap-3 px-4 py-3 border border-gray-300 
              rounded-md shadow-sm bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 
              focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
              transition-colors duration-200"
            >
              <img src={googleSvg} alt="Google" className="w-5 h-5" />
              <span>Continue with Google</span>
            </button>

            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with email</span>
              </div>
            </div>

            {/* Email Form */}
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email address
                </label>
                <div className="mt-1">
                  <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="work@company.com"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 
                    rounded-md shadow-sm placeholder-gray-400 
                    focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>
              </div>

              {isMagicLinkSent && (
                <div className="rounded-md bg-blue-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-blue-700">
                        Check your inbox! We've sent you a magic link to sign in.
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <ButtonPrimary
                loading={isLoading}
                type="submit"
                className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md 
                shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                transition-colors duration-200"
              >
                Get Magic Link
              </ButtonPrimary>
            </form>

            <p className="mt-4 text-xs text-center text-gray-600">
              By continuing, you agree to our 
              <a href="https://valuable-crate-b4c.notion.site/Terms-And-Conditions-1099e55f776d8093b007fdd60f4c3b85?pvs=73" target="_blank" className="text-blue-600 hover:underline"> Terms of Service </a> 
              and 
              <a href="https://valuable-crate-b4c.notion.site/Privacy-Policy-1099e55f776d802e95c1e373377c4472" target="_blank" className="text-blue-600 hover:underline"> Privacy Policy </a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(PageLogin);
