import { makeAutoObservable, reaction } from "mobx";
import { collection, query, where, onSnapshot, doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import { authStore } from "./AuthStore";

class ChatStore {
    unreadConversations: string[] = [];
    hasUnreadMessages: boolean = false;
    activeConversationId: string | null = null;
    private messageListener: (() => void) | null = null;

    constructor() {
        makeAutoObservable(this);
        
        // React to auth changes
        reaction(
            () => authStore.currentUser?.id,
            (userId) => {
                if (userId) {
                    this.initializeMessageListener(userId);
                } else {
                    this.cleanupMessageListener();
                }
            },
            { fireImmediately: true }
        );
    }

    private initializeMessageListener(userId: string) {
        // Cleanup existing listener if any
        this.cleanupMessageListener();

        const conversationsRef = collection(firestore, 'conversations');
        const q = query(
            conversationsRef,
            where(`status.${userId}`, '==', 'not-seen')
        );

        // Set up new listener
        this.messageListener = onSnapshot(q, (snapshot) => {
            const unreadConversations = snapshot.docs.map(doc => doc.id);
            this.setUnreadConversations(unreadConversations);
        });
    }

    private cleanupMessageListener() {
        if (this.messageListener) {
            this.messageListener();
            this.messageListener = null;
        }
    }

    private setUnreadConversations(conversations: string[]) {
        this.unreadConversations = conversations;
        this.hasUnreadMessages = conversations.length > 0;
    }

    setActiveConversation(conversationId: string) {
        this.activeConversationId = conversationId;
        this.markConversationAsRead(conversationId);
    }

    clearActiveConversation() {
        this.activeConversationId = null;
    }

    async markConversationAsRead(conversationId: string) {
        const userId = authStore.currentUser?.id;
        if (!userId) return;

        const conversationRef = doc(firestore, 'conversations', conversationId);
        await updateDoc(conversationRef, {
            [`status.${userId}`]: 'seen'
        });

        this.unreadConversations = this.unreadConversations.filter(id => id !== conversationId);
        this.hasUnreadMessages = this.unreadConversations.length > 0;
    }

    setUnreadMessages(value: boolean) {
        this.hasUnreadMessages = value;
    }

    isConversationUnread(conversationId: string): boolean {
        return this.unreadConversations.includes(conversationId);
    }

    // Cleanup on store destruction
    dispose() {
        this.cleanupMessageListener();
    }
}

export const chatStore = new ChatStore(); 