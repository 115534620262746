import CardTripBox from "components/CardTripBox/CardTripBox";
import useNcId from "hooks/useNcId";
import { FC } from "react";
import 'swiper/css';
import { Swiper, SwiperSlide } from "swiper/react";
import { CardSkeleton } from "components/Skeleton/CardSkeleton";

export interface SectionSliderMatchingBusinessTravelersProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  userMatches?: any[];
  itemPerRow?: 4 | 5;
  hasNextPrev?: boolean;
  isCenter?: boolean;
  uniqueClassName: string;
  isLoading?: boolean;
}


const SectionSliderMatchingBusinessTravelers: FC<SectionSliderMatchingBusinessTravelersProps> = ({
  className = "",
  itemClassName = "",
  userMatches = [],
  itemPerRow = 5,
  hasNextPrev = true,
  uniqueClassName,
  isLoading = false,
}) => {
  const UNIQUE_CLASS = "SectionSliderNewCategories__" + uniqueClassName + useNcId();

  const renderLoading = () => {
    
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {Array(4).fill(null).map((_, index) => (
          <CardSkeleton key={index} />
        ))}
      </div>
    );
  };

  return (
    <div className={`nc-SectionSliderNewCategories ${className}`}>
      <div className={`${UNIQUE_CLASS} flow-root`}>
          <Swiper 
            className="w-full h-full mySwiper"
            slidesPerView={1.5}
            spaceBetween={30}
            breakpoints={ {
              1280: {
                slidesPerView: itemPerRow - 1,
              },
              1024: {
                spaceBetween: 20,
                slidesPerView: itemPerRow - 1,
              },
              768: {
                spaceBetween: 20,
                slidesPerView: itemPerRow - 2,
              },
              640: {
                spaceBetween: 20,
                slidesPerView: itemPerRow - 3,
              },
              500: {
                spaceBetween: 20,
                slidesPerView: 1.5,
              },
            }}
          >
            {isLoading ? (
              renderLoading()
            ) : (
              userMatches.map((match, index) => (
                <SwiperSlide key={index} className="flex justify-center items-center text-center text-lg">
                  <CardTripBox index={index + 1} tripDetails={match} />
                </SwiperSlide>
              ))
            )}
          </Swiper>
      </div>
    </div>
  );
};

export default SectionSliderMatchingBusinessTravelers;
