import { TripDetails } from "interface/trip.interface";
import moment from "moment-timezone";
import Heading from "components/Heading/Heading";
import NoResultCard from "components/NoResult/GenericNoResultCard";
import SectionSliderMatchingBusinessTravelers from "components/SectionSliderMatchingBusinessTravelers/SectionSliderMatchingBusinessTravelers";

interface TripSectionProps {
  currentTrip: TripDetails | null;
  localsAtDestination: any[];
  matchingTravelers: any[];
  isLoading: boolean;
}

export const TripSection = ({ currentTrip, localsAtDestination, matchingTravelers, isLoading }: TripSectionProps) => {
  return (
    <>
      <Heading className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50" 
        desc={`${moment(currentTrip?.startDate).startOf('day').format('ll')} - ${moment(currentTrip?.endDate).endOf('day').format('ll')}`}>
        Flight to {currentTrip?.toCity}
      </Heading>

      <Heading className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50" desc=""
        viewAll={{ label: "View all", href: `professional/locals/${currentTrip?.id}` }}>
        Connect with Locals at Your Destination
      </Heading>

      {localsAtDestination.length ? (
        <SectionSliderMatchingBusinessTravelers
          userMatches={localsAtDestination}
          uniqueClassName="MatchingLocalsAtDestination"
          isLoading={isLoading}
        />
      ) : (
        <NoResultCard displayName="No locals at destination" />
      )}

      <Heading className="mb-5 lg:mb-5 text-neutral-500 dark:text-neutral-50" desc=""
        viewAll={{ label: "View all", href: "professional/to-destination" }}>
        Meet Fellow Business Travelers at Your Destination
      </Heading>

      {matchingTravelers.length ? (
        <SectionSliderMatchingBusinessTravelers
          userMatches={matchingTravelers}
          uniqueClassName="MatchingTravelersToDestination"
        />
      ) : (
        <NoResultCard displayName="No matching travelers" />
      )}
    </>
  );
}; 