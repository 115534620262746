import BulletinCardMeta from "components/BulletinCardMeta/BulletinCardMeta";
import { BulletinResponse } from "interface/communities";
import { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import ShowMoreText from "react-show-more-text";

export interface DigitalBulletinBoardCardProps {
  className?: string;
  bulletin: BulletinResponse;
}

const DigitalBulletinBoardCard: FC<DigitalBulletinBoardCardProps> = ({ className = "h-full", bulletin }) => {
  const { title, content } = bulletin;
  const href = `/bulletin/${bulletin.id}/page`;

  return (
    <div
      className={`nc-DigitalBulletinBoardCard relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center ${className}`}
      data-nc-id="DigitalBulletinBoardCard"
    >
      <Link to={href} className=" absolute inset-0" title={title}></Link>
      <div className="relative space-y-2">
        <BulletinCardMeta meta={{ ...bulletin }} />
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <Link to={href} className=" line-clamp-2" title={title}>
            {title}
          </Link>
        </h2>
        <span className="mt-4 text-neutral-500 dark:text-neutral-400 ">
          <ShowMoreText width={280}>
            {content}
          </ShowMoreText>
        </span>
      </div>

      {/* <Link
        to={href}
        title={title}
        className={`block sm:w-20 flex-shrink-0 relative rounded-lg overflow-hidden mb-5 sm:ml-4 sm:mb-0 group`}
      >
        <div className={`w-full h-0 aspect-w-16 aspect-h-9 sm:aspect-h-16`}>
          <NcImage
            containerClassName="absolute inset-0"
            className="nc-will-change-transform object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
            src={''}
            title={title}
          />
        </div>
      </Link> */}
    </div>
  );
};

export default DigitalBulletinBoardCard;
