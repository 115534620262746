import { getMetadata } from "api/metadata";
import Autocomplete from "components/AutoComplete/AutoComplete";
import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Input from "shared/Input/Input";
import { onboardingStore } from "stores/OnboardingStore";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

interface OnBoardingStep2Props { }
interface OptionItem {
  id: number;
  label: string;
  value: string;
  tag?: string | null;
}
export interface Metadata {
  industries: OptionItem[];
  roles: OptionItem[];
}

const OnBoardingStep2: FC<OnBoardingStep2Props> = observer(() => {
  const [metadata, setMetdata] = useState<Metadata>({
    industries: [],
    roles: []
  })

  useEffect(() => {
    getMetadata().then(res => {
      const { data } = res
      setMetdata(data)
    })

    // Check if 'industry' exists in localStorage and set it in onboardingStore
    let enterpriseParams: any = localStorage.getItem('enterpriseParams');

    if (enterpriseParams) {
      enterpriseParams = JSON.parse(enterpriseParams);
    }

    if (enterpriseParams?.industry) {
      onboardingStore.setIndustry(enterpriseParams?.industry);
    }

    if (enterpriseParams?.company_name) {
      onboardingStore.setCompanyName(enterpriseParams?.company_name);
    }
  }, [])

  const handleRoleChanges = (role: string) => {
    onboardingStore.setRole(role)
  }

  const handleIndustryChanges = (industry: string) => {
    onboardingStore.setIndustry(industry)
  }

  const handleCompanyNameChanges = (event: React.ChangeEvent<HTMLInputElement>) => {
    onboardingStore.setCompanyName(event.target.value)
  }

  const handleContinue = () => {
    if (onboardingStore.industry && onboardingStore.role) {
      onboardingStore.setOnboardingNextStep()
    } else {
      toast.error('Please complete the form')
    }
  }

  const handlePrevStep = () => onboardingStore.setOnboardingPrevStep()

  return (
    <CommonLayout
      index="2"
      onContinue={handleContinue}
      onBack={handlePrevStep}
    >
      <>
        <h2 className="text-2xl font-semibold">Professional Details</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-8">
          {/* ITEM */}
          <FormItem
            label="Job Title"
          // desc="Select your job title from the list. This helps us to provide more relevant information and opportunities tailored to your professional role."
          >
            <Autocomplete options={metadata.roles.map(r => r.value)} placeholder="Select your job title" onChange={handleRoleChanges} />

            {/* <Select onChange={handleRoleChanges}>
              <option value="">Select Job Title</option>
              {metadata.roles.map(role => <option value={role.value}>{role.label}</option>)}
            </Select> */}
          </FormItem>
          <FormItem
            label="Industry Type"
          // desc="Select the industry type that best represents your professional area. This will help in providing more targeted and relevant information for your field."
          >
            <Autocomplete defaultValue={onboardingStore.industry} options={metadata.industries.map(i => i.value)} placeholder="Select the industry type" onChange={handleIndustryChanges} />

            {/* <Select onChange={handleIndustryChanges}>
              <option value="">Select Industry Type</option>
              {metadata.industries.map(industry => <option value={industry.value}>{industry.label}</option>)}
            </Select> */}
          </FormItem>
          {/* <FormItem
            label="Company Name"
            desc="Enter the name of the company where you are currently employed."
          >
            <Input onChange={handleCompanyNameChange} />
          </FormItem> */}
          <FormItem
            label="Company Name"
          // desc="Enter the name of the company where you are currently employed."
          >
            <Input onChange={handleCompanyNameChanges} value={onboardingStore.companyName || ''} placeholder="Enter your company name" />
          </FormItem>
        </div>
      </>
    </CommonLayout>
  );
})
export default OnBoardingStep2;

